.checkbox label:after {
  content: "";
  display: table;
  clear: both;
}

.checkbox .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #a9a9a9;
  border-radius: 0.25em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: 0.5em;
}

.checkbox .cr .cr-icon {
  position: absolute;
  font-size: 0.8em;
  line-height: 0;
  top: 50%;
  left: 20%;
}

.checkbox label input[type="checkbox"] {
  display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon {
  opacity: 0;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon {
  opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr {
  opacity: 0.5;
}

.form-busca {
  margin-bottom: 20px;
}

.badge-lit {
  color: #28abe2;
  border: 1px solid #28abe2;
  margin-right: 10px;
}

.badge-lit a {
  margin-left: 1em;
  font-size: 1.2em;
}

.resultado-noticia img {
  max-height: 172px;
  max-width: 315px;
}

.resultado-noticia a {
  color: #007bff !important;
  font-weight: 700;
}

.wrap-content {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
