:root {
  --transparent: transparent;
  --white: #ffffff;
  --black: #000000;
  --gray: #777777;
  --green: #6da544;
  --red: #ff0000;
  --blue: #27aee1;
  --yellow: #ffda44;

  --default: #777777;
  --default-dark: #212529;
  --default-light: #f6f6f6;
  --default-blue: #03aeeb;
  --default-green: #6bda55;
  --default-yellow: #d7df23;
  --default-gray: #777777;

  --background-default: #ffffff;
  --background-light-gray: #fcfcfc;
  --background-extra-light-gray: #f6f6f6;
  --background-light-blue: #e0f6ff;
  --background-gradient-primary: #03aeeb;
  --background-gradient-secondary: #fef101;

  --border-gray: #dddddd;
  --border-light-gray: #f3f2f1;
  --border-input-focus: #03aeeb;
  --border-textarea-focus: #03aeeb;

  --image-background: #f3f2f1;
  --image-border: #f3f2f1;
  --image-border-light: #e0f6ff;
  --image-member-border: #777777;

  --textarea-border: #d8d8d8;

  --title-primary: #212529;
  --title-light-primary: #585858;

  --text-light: #ffffff;
  --text-primary: #777777;
  --text-alert: #03aeeb;
  --text-danger: #ff0000;
  --text-light-primary: #d8d8d8;
  --text-dark-primary: #585858;

  --button-primary: #03aeeb;
  --button-primary-hover: #585858;
  --button-primary-text: #ffffff;
  --button-primary-border: transparent;
  --button-outline-primary: #03aeeb;
  --button-outline-primary-hover: #03aeeb;
  --button-outline-primary-text: #03aeeb;
  --button-outline-primary-hover-text: #ffffff;
  --button-outline-light: #ffffff;
  --button-outline-light-hover: #ffffff;
  --button-outline-light-text: #ffffff;
  --button-outline-light-hover-text: #777777;
  --button-light-primary: #e0f6ff;
  --button-secondary: #fef101;
  --button-secondary-hover: #585858;
  --button-secondary-text: #777777;
  --button-secondary-border: transparent;
  --button-secondary-hover-text: #ffffff;
  --button-outline-secondary: #fef101;
  --button-success: #6bda55;
  --button-outline-success: #6bda55;
  --button-danger: #ff0000;
  --button-outline-danger: #ff0000;
  --button-close: #f6f6f6;
  --button-close-border: #8d9dae;
  --button-close-text: #8d9dae;
  --button-close-hover: #e0f6ff;
  --button-modal-confirm: #03aeeb;
  --button-modal-confirm-hover: #585858;
  --button-modal-confirm-text: #ffffff;
  --button-gradient-primary: #03aeeb;
  --button-gradient-secondary: #6bda55;
  --button-gradient-border: #03aeeb;
  --button-gradient-hover: #ffffff;
  --button-gradient-hover-text: #777777;
  --button-link-text: #777777;
  --button-link-hover-text: #03aeeb;
  --button-link-active-text: #03aeeb;
  --button-link-active-hover-text: #777777;
  --button-link-active-icon: #03aeeb;
  --button-light-gray: #f6f6f6;
  --button-light-gray-border: #dddddd;
  --button-light-gray-text: #585858;
  --button-light-gray-hover: #e0f6ff;
  --button-light-gray-hover-text: #03aeeb;
  --button-disabled-border: transparent;
  --button-pagination: transparent;
  --button-pagination-text: #777777;
  --button-pagination-border: transparent;
  --button-pagination-hover: #f6f6f6;
  --button-pagination-hover-border: #dddddd;
  --button-pagination-hover-text: #585858;
  --button-modal: transparent;
  --button-modal-text: #777777;
  --button-modal-icon: #585858;
  --button-modal-hover: transparent;
  --button-modal-hover-underline: #03aeeb;
  --button-arrow-background: #00000060;
  --button-arrow-text: #ffffff;

  --icon-default: #777777;

  --badge-shadow: #d8d8d8;
  --badge-success: #6bda55;
  --badge-success-text: #ffffff;
  --badge-danger: #ff0000;
  --badge-danger-text: #ffffff;

  --search-border: #f3f2f1;
  --search-border-focus: #03aeeb;
  --search-text-focus: #585858;
  --topsearch-gradient-primary: #89d26e;
  --topsearch-gradient-secondary: #e4ea1a;

  --block-background-light: #ffffff;
  --block-border-light: transparent;
  --block-background-primary: #f6f6f6;
  --block-border-primary: #dddddd;
  --block-background-edit: #fcfcfc;
  --block-border-edit: #e0f6ff;

  --block-edit-background: #f6f6f6;
  --block-edit-border: #dddddd;
  --block-gradient-primary: #03aeeb;
  --block-gradient-secondary: #d7df23;
  --block-gradient-border: #6bda55;
  --block-feed-shadow: #e8e8e8;
  --block-evaluation: #34b0ae;
  --block-evaluation-border: #27aae1;
  --block-evaluation-shadow: #cccccc;

  --table-striped-odd: #f6f6f6;
  --table-striped-even: #ffffff;

  --overflow-bar: #585858;
  --overflow-track: #e0e4fb;
  --overflow-bar-shadow: #777777;

  --link-primary: #03aeeb;
  --link-icon-primary: #03aeeb;
  --link-primary-hover: #777777;

  --navtab-border: transparent;
  --navtab-active-border: #03aeeb;

  --divisor-gray: #777777;
  --divisor-primary: #03aeeb;
  --divisor-secondary: #fef101;
  --divisor-light-gray: #dddddd;
  --divisor-light: #fcfcfc;
  --divisor-message: #cccccc;

  --dropdown-primary: transparent;
  --dropdown-primary-hover: transparent;
  --dropdown-primary-text: #777777;
  --dropdown-primary-text-hover: #03aeeb;
  --dropdown-primary-hover: #03aeeb;

  --modal-text: #777777;
  --modal-dark-text: #585858;
  --modal-light-text: #ffffff;
  --modal-light-header: #fcfcfc;

  --dropzone-border: #d8d8d8;
  --dropzone-text: #777777;
  --dropzone-text-focus: #03aeeb;
  --dropzone-border-focus: #fef101;

  --post-create: #ffffff;
  --post-create-border: #777777;
  --post-create-text: #777777;
  --post-text: #777777;

  --timeline-border: #dddddd;
  --timeline-card-header: transparent;

  --checkbox-primary: #03aeeb;
  --checkbox-primary-text: #777777;

  --tag-primary: #f6f6f6;
  --tag-primary-border: #dddddd;
  --tag-primary-text: #777777;

  --menu-mobile: #03aeeb;
  --menu-mobile-overlay: #00000085;
}
