@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~react-big-calendar/lib/css/react-big-calendar.css";
@import '~react-responsive-modal/styles.css';
@import url("https://fonts.googleapis.com/css?family=Dosis:200,300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");

#root{
  max-width: 1920px;
  margin: 0 auto; /* Isso centraliza o conteúdo horizontalmente */
  background-color: white;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  min-height: 100vh;
}

html {
  overflow-x: hidden;
}

.fb_reset {
  z-index: -1;
  display: none !important;
  position: fixed;
}

* {
  outline: none;
}

body,
html,
body > div {
  font-family: "Lato", sans-serif !important;
  height: 100%;
  background: #e4e4e4;
}

h1, h2, h3, h4, h5 {
  font-family: "Dosis", sans-serif;
}

img {
  max-width: 100%;
}

#modal {
  display: none;
}

.form-error {
  font-size: 75%;
  color: red;
  /* margin-top: -1em; */
}

.bold {
  color: #000;
  font-weight: bold;
}

input#inputPassword:disabled {
  background: #e9ecef !important;
  cursor: not-allowed;
}

textarea {
  box-sizing: border-box;
  resize: none !important;
  width: 100%;
}

.timeline-center {
  text-align: center;
}

.input-not-yellow:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #f3f2f1 inset;
}

.d-inline {
  display: inline;
}

.btn {
  cursor: pointer;
}

.btn-rounded-small {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #e9e9e9;
}

.btn-rounded-small i {
  font-size: 15px;
  text-align: center;
  color: #fff;
  padding: 0;
  margin: 0;
}

.btn-rounded {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background-color: #a7a8a9;
}

.btn-login {
  background: #00a0eb;
  color: #fff;
  border-radius: 10px;
  width: 110px;
  float: right;
  font-size: 20px;
  text-align: center;
}

.btn-green {
  background: #43dba2;
  border-color: #43dba2;
  border-radius: 10px;
  color: #fff;
  text-align: center;
}

.btn-blue {
  background: #27aae1;
  border-color: #27aae1;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  box-shadow: 0 3px 10px rgb(39 170 225 / 30%)
}

.btn-full{
  width: 100%;
}

.btn-white-outline {
  background: transparent;
  color: #fff;
  border-color: #fff;
  border-radius: 10px;
  text-align: center;
}

.btn-loading {
  position: relative;
  opacity: 0.8;
  color: #27aae1 !important;
  text-shadow: none !important;
}

.actionBtn {
  padding: 4px 10px;
  border-radius: 30px;
  min-width: 120px;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  border: 2px solid transparent;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;

  background: white;
  color: #27aae1;
  border: 2px solid #27aae1;
}

.actionBtn.span {
  display: inline-block;
  margin: 0 5px;
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

.actionBtn:hover {
  color: white;
  background: #27aae1;
}

.is-clickable {
  cursor: pointer;
}

.btn-light {
  background-color: #fff;
  border-color: #fff;
  border-radius: 10px;
}

@-webkit-keyframes ld {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
            transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(180deg) scale(1.1);
            transform: rotate(180deg) scale(1.1);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
            transform: rotate(360deg) scale(1);
  }
}

@keyframes ld {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
            transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(180deg) scale(1.1);
            transform: rotate(180deg) scale(1.1);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
            transform: rotate(360deg) scale(1);
  }
}

.btn-loading:before {
  content: "";
  display: inline-block;
  position: absolute;
  background: transparent;
  border: 1px solid #fff;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
  width: 24px;
  height: 24px;
  -webkit-animation: ld 1s ease-in-out infinite;
  animation: ld 1s ease-in-out infinite;
}

.btn-null:hover {
  color: white;
}

.btn-loading:hover,
.btn-loading:active,
.btn-loading:focus {
  cursor: default;
  color: transparent;
  outline: none !important;
  box-shadow: none;
}

.btn-lightblue {
  background: #b9e0f2;
  border-color: #b9e0f2;
  border-radius: 10px;
  color: #27aae1;
}

.btn-white {
  background: #fff;
  border-color: #fff;
  border-radius: 10px;
  color: #27aae1;
}

.btn-grey {
  background: #e9e9e9;
  border-color: #e9e9e9;
  border-radius: 10px;
  color: #27aae1;
  font-weight: 200;
}

.btn-icon {
  background: none;
  border-color: none;
  color: #333;
}

.btn-nivelamento {
  background: #27aae1;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  width: 15em;
}

.btn-certicado {
  background: #27aae1;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  /* width: 15em;
  margin-top: 5px; */
}

.btn-nivelamento-small {
  background: #27aae1;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  width: 8em;
}

.btn-outline-primary {
  border-color: #27aae1;
  color: #27aae1;
}

.btn-outline-primary:hover {
  border-color: #27aae1;
  background-color: #27aae1;
  color: #fff;
}

.btn-color-blue {
  color: #27aae1;
  background: transparent;
}

.grupoBtnPedidosAberto {
  margin-left: 5px;
}

.bg-grey {
  background: #e9e6e4;
  padding: 10px;
}

.btn-FontWeight6 {
  font-weight: 600;
}

.bg-blue {
  background: #27aae1;
  color: #fff;
  padding: 2px;
}

.bg-dark-grey {
  background: #323232;
  border-color: #323232;
  border-radius: 0px;
}

.bg-dark-grey li,
.bg-dark-grey p {
  margin: 10px;
}

.bg-dark-grey ul {
  padding: 0;
  margin-bottom: 0;
}

.icon-curso {
  width: 24px;
  height: 23.33px;
}

/* #curso canvas {
  width: 1200px !important;
} */

.divider {
  border-bottom: 1px solid lightgray;
  padding-bottom: 0.5em;
}

.page-link {
  background-color: transparent;
  padding: 0;
  color: #fff;
  border: transparent;
}

.bg-dark-grey li i {
  font-size: 32px;
  color: #fff;
}

.page-item.disabled .page-link,
.page-item .page-link {
  background: transparent;
  border-color: transparent;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-bottom-40 {
  margin-bottom: 40px;
}

.padding-15 {
  padding: 15px;
}

.padding-0 {
  padding: 0px !important;
}

@-webkit-keyframes ef-pulse-grow {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@keyframes ef-pulse-grow {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.ef {
  width: 100%;
}

.ef-pulse-grow:hover,
.ef-pulse-grow:focus,
.ef-pulse-grow-active {
  -webkit-animation-name: ef-pulse-grow;
  animation-name: ef-pulse-grow;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.img-carreira {
  width: 200px;
  height: 200px;
}

.padding-0-r {
  padding-right: 0px !important;
}

@media screen and(max-width:1221px) {
  .padding-0 {
    padding: 10px !important;
  }
}

.divisoriaBefore:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #a7a8a9;
}

.divisoriaAfter:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #a7a8a9;
  margin: 5px;
}

.container-blank {
  background: #fff;
  padding: 10px;
  margin: 10px 0;
}

#navbar-home .nav-item {
  margin: auto 0;
}

a {
  color: #27aae1;
  cursor: pointer;
  text-decoration: none;
}

.grupos-social-img {
  padding-left: 0px;
}

.grupos-social-text {
  padding-right: 0px;
  padding-left: 40px;
  margin-bottom: 0.5em;
}

@media (max-width: 1200px) and (min-width: 768px) {
  .grupos-social-img {
    padding-left: 15%;
  }
  .grupos-social-text {
    display: none !important;
  }
  ul.list-group {
    padding-right: 0;
  }
  .card .list-group-item {
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .card .list-group-item .group-desc,
  .list-group-item .member {
    display: none !important;
  }
  .card-header.sidebar {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

/* ---------------- LOGIN ----------------*/

#login,
#cadastro {
  background: #fff !important;
}

#login .bg-loginLeft,
#cadastro .bg-cadastroLeft {
  padding: 0 1em;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#login .bg-loginLeft.big,
#cadastro .bg-cadastroLeft.big {
  min-height: 100vh;
}

#login .bg-loginLeft.small,
#cadastro .bg-cadastroLeft.small {
  padding: 1em 5px;
}

#login .bg-loginLeft.small .logo,
#cadastro .bg-cadastroLeft.small .logo {
  height: auto;
  width: 289px;
  max-width: 100%;
}

#login .bg-loginRight,
#cadastro .bg-cadastroRightt {
  color: #58595b;
}

#login .bg-loginLeft .logo,
#cadastro .bg-cadastroLeft .logo {
  width: 100%;
  max-width: 578px;
}

#login .bg-loginLeft {
  background-color: #27aae1;
  filter: progid:DXImageTransform.Microsoft.gradient( GradientType=1, startColorstr=#27AAE1, endColorstr=#FCEE1E);
  background: linear-gradient(
    45deg,
    rgba(2, 196, 255, 1) 25%,
    rgba(142, 230, 123, 1) 100%
  );
  background: -o-linear-gradient(
    45deg,
    rgba(2, 196, 255, 1) 25%,
    rgba(142, 230, 123, 1) 100%
  );
}

#login .bg-loginLeftPromo {
  background-image: #fff;
}

#login .bg-color {
  background: #fcee1e !important;
}

#login .bg-loginLeftPromo,
#cadastro .bg-cadastroLeft {
  padding: 0 1em;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#login .bg-loginLeftPromo.big,
#cadastro .bg-cadastroLeft.big {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: linear-gradient(
    to right,
    rgb(249 238 32) 0%,
    rgb(76 170 224) 100%
  );
  padding: 0;
}

@media (max-width: 830px) {
  #login .bg-loginLeftPromo.big,
  #cadastro .bg-cadastroLeft.big {
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

#login #bg-rightside .login-title h3,
#login #bg-rightside .esqueci-senha,
#login .criar-conta span,
#cadastro #bg-rightside .cadastro-title h3 {
  color: #27aae1;
}

.criar-conta a {
  font-weight: 700;
}

#login .login-title h3,
#cadastro .cadastro-title h3 {
  font-size: 2.2em;
  font-weight: 400px !important;
  margin-bottom: 0.5em;
}

#login #bg-rightside .info-login p,
#cadastro #bg-rightside .info-cadastro p {
  font-size: 14px;
  color: #58595b;
  margin-bottom: 1.5em;
}

#bg-rightside .social-buttons button {
  font-size: 25px;
  padding: 0px;
  text-align: center;
}

.entre-com p {
  color: #58595b !important;
  margin-top: 20px;
  margin-bottom: unset;
}

.metodos-acesso {
  margin: 10px;
}

.metodos-acesso i {
  color: #fff;
  margin: 5px 0;
}

.social-buttons {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.social-buttons button {
  margin: 0 5px;
}

.social-buttons i {
  font-size: 1em;
}

.buttonLogin {
  border: none;
  padding: 10px;
  display: block;
  margin: 20px auto;
  text-transform: uppercase;
  text-align: center;
  color: fff;
  font-size: 15px;
}

.buttonLogin i {
  margin-right: 5px;
}

.buttonLoginNormal {
  background-color: #27aae1;
  width: 100%;
  margin-top: 35px;
}

.buttonFacebookLogin {
  background-color: #39579a;
  width: 70%;
}

.buttonGoogleLogin {
  background-color: #db4931;
  width: 70%;
}

.buttonLinkedinLogin {
  background-color: #0077b5;
  width: 70%;
}

.h-login {
  font-family: "Lato", sans-serif;
  font-size: 25px;
  font-weight: lighter;
  margin-bottom: 10px !important;
  font-stretch: condensed;
}

.margingb-10 {
  margin-bottom: 10px;
}

.form-check-input {
  margin-right: 5px;
}

.font-description p {
  margin-bottom: 8px;
}

.profile-form-row {
  margin-bottom: 10px;
}
.profile-form-row label {
  margin-bottom: 2px;
  display: block;
  font-size: 17px;
}
.profile-form-row label i {
  margin-right: 3px;
}
.profile-form-row label .icon-space {
  margin-right: 3px;
}

.profile-form-column label {
  margin-bottom: 2px;
  display: block;
  font-size: 17px;
}

.profile-form-row input {
  height: 25px;
  border: none;
  border-bottom: 2px solid #c4c4c4c4;
  color: #414141;
  width: 100%;
  padding-left: 2%;
  background: none;
}

.profile-inner {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  position: relative;
}
.profile-inner .profile-form-content {
  width: 100%;
  padding: 1.8% 0.5%;
  background-color: #ffffffe1;
  box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.4);
  -ms-box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.4);
  -o-box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.4);
}
.profile-inner .profile-form-content .profile-form-inner {
  height: 100%;
  padding: 1% 2%;
}
.profile-inner
  .profile-form-content
  .profile-form-inner.profile-form-inner-last {
  padding: 0;
  display: block;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.profile-inner .profile-form-content .profile-form-inner .profile-form-row {
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.profile-inner .profile-form-content .profile-form-inner .profile-form-column {
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.profile-form-holder.profile-select-holder select.profile-form-control {
  padding-bottom: 0;
  border: none;
  border-bottom: 2px solid #c4c4c4c4;
  height: 35px;
  padding: 0 9px;
  background: none;
  color: #414141;
}
.profile-form-holder {
  position: relative;
}
.profile-form-holder.profile-select-holder {
  width: auto;
  display: inline-block;
  margin-right: 10px;
}
/* ---------- Cadastro ----------- */

#cadastro .bg-cadastroRight {
  background-color: #f3f2f1;
}

#cadastro .bg-cadastroLeft {
  background-color: #27aae1;
  filter: progid:DXImageTransform.Microsoft.gradient( GradientType=1, startColorstr=#27AAE1, endColorstr=#FCEE1E);
  background-image: linear-gradient(to right, #27aae1 0%, #fcee1e 100%);
  background-image: -o-linear-gradient(to right, #27aae1 0%, #fcee1e 100%);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    right bottom,
    color-stop(0%, #27aae1),
    color-stop(100%, #fcee1e)
  );
}

#cadastro #bg-rightside .login-title h3,
#cadastro #bg-rightside .esqueci-senha,
#cadastro .criar-conta span {
  color: #27aae1;
}

.esqueci-senha {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

/* ---------- Pagamento ----------- */

.aviso {
  position: fixed;
  z-index: 1000;
  width: 100%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

#pagamento {
  background-color: #f8f8f8;
  min-height: 100vh;
  height: 100%;
  overflow-y: auto;
}

.bg-gradient-lit{
  background-image: linear-gradient(
    45deg,
    rgba(2, 196, 255, 1) 25%,
    rgba(142, 230, 123, 1) 100%
  );
}

#ajuda {
  background-image: #eee;
  min-height: 100vh;
  height: 100%;
  padding-bottom: 20px;
  overflow-y: auto;
}

#pagamento .informacoes {
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  background: transparent;
  text-align: center;
  color: white;
  padding: 0;
}

#pagamento .formulario {
  padding: 1em 2em;
  /* max-width: 650px; */
  margin: 0 auto;
  border-radius: 12px;
}

#pagamento .bg-pagamentoRight {
  background-color: #fff;
}

#pagamento--resultado-pagamento {
  margin: 0;
}

#pagamento--resultado-pagamento .valor-desc {
  font-size: 6rem;
  font-weight: 600;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}

#pagamento--resultado-pagamento .valor-desc sup {
  font-size: 45%;
  line-height: normal;
  top: 0.65em;
}

#pagamento--resultado-pagamento .valor-desc .ano {
  font-size: 45%;
  margin-left: 0.5em;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 0.85em;
}

#pagamento--resultado-pagamento .valor-desc .ano .anual {
  margin-bottom: -0.35em;
}

#pagamento--resultado-pagamento .valor-desc .mes {
  font-size: 45%;
  margin-left: 0.5em;
  margin-top: 1.35em;
}

#pagamento--resultado-pagamento .valor-desc .ano .unica {
  font-weight: 300;
  font-size: 70%;
}

#pagamento #card-wrapper {
  margin-bottom: 10px;
}

#pagamento--comece-estudar span {
  font-weight: bold;
  color: #58595b;
}

#pagamento--comece-estudar {
  background: #fff;
  color: #222;
  color: #58595b;
  padding: 10px;
  padding-left: 3em;
  padding-right: 3em;
  margin: 20px 0;
}

.btn-white.pagamento {
  font-weight: bold;
  padding-left: 2em;
  padding-right: 2em;
  margin-right: 1em;
  background: rgba(255, 255, 255, 0.5);
  border: none;
}

.btn.btn-white.pagamento.active {
  background: white;
}

#pagamento .container-img {
  background: transparent;
  color: #000;
  margin-bottom: 5px;
}

#pagamento--assine-lit h1 {
  font-weight: 300;
}

#pagamento--assine-lit p {
  font-size: 18px;
  color: #fff;
}

#pagamento input[type="text"],
#pagamento input[type="text"]:invalid {
  padding-left: 0;
  border: none;
  background: none;
  font-size: 1.1em;
  box-shadow: none !important;
}

#pagamento .input-group-append {
  border: none !important;
  background: none;
  border-radius: 0;
  box-shadow: none !important;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.2em;
}

.total-pagamento {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.box-buttons {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.box-buttons .infos,
.box-buttons button {
  -webkit-flex-basis: 50%;
      -ms-flex-preferred-size: 50%;
          flex-basis: 50%;
}

.total-pagamento .infos,
.total-pagamento button {
  -webkit-flex-basis: 50%;
      -ms-flex-preferred-size: 50%;
          flex-basis: 50%;
}

.total-pagamento .infos .title {
  font-size: 1em;
  font-weight: 300;
}

.total-pagamento .infos .value {
  font-size: 2em;
  color: #27aae1;
  font-weight: 600;
  display: block;
  margin-top: -10px;
}

.accepted-cards {
  margin-bottom: 1em;
}

.accepted-cards img {
  margin-right: 10px;
  max-height: 38px;
  width: auto;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 20px;
}

.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

.img-cartao {
  width: 100%;
}

.bg-pagamentoLeft {
  color: #fff;
}

#bg-pagamentoLeft--comece-estudar {
  background: #fff;
  color: #222;
  padding: 10px 5px;
  margin: 20px 0;
}

.descricaoPagamento {
  padding: 15px;
  margin: 15px;
}

.cadastroPagamento {
  border: 1px solid #a7a8a9;
  padding: 15px;
  margin: 15px;
}

.totalCompra {
  background: #f0f0f0;
  padding: 5px;
}

.valorCurso {
  border-right: 1px solid #a7a8a9;
  margin-right: 15px;
}

.descricaoPagamento p {
  font-size: 11px;
}

.descricaoPagamento .descricaoBold {
  font-weight: bold;
}

.descricaoPagamento .logo {
  margin-bottom: 20px;
}

.markUp i.fa {
  color: #fff;
  font-size: 22px;
  padding: 14px;
}

.avatar {
  margin: 0 auto;
  margin-top: 15px;
}

.avatar-body {
  padding: 10px;
}

.disciplinas {
  padding-left: 15px;
  font-size: 1.1em;
  font-weight: 500;
}

.description h4,
#home .avatar-body h6,
.disciplinas {
  color: #27aae1;
}

.sobreCurso h4,
.avatar-body span {
  color: #7a7a7a;
}

/* ---------------- Navbar superior --------------- */

#navbar-superior {
  background-color: #27aae1;
  filter: progid:DXImageTransform.Microsoft.gradient( GradientType=1, startColorstr=#27AAE1, endColorstr=#FCEE1E);
  background-image: linear-gradient(to right, #27aae1 0%, #fcee1e 100%);
  background-image: -o-linear-gradient(to right, #27aae1 0%, #fcee1e 100%);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    right bottom,
    color-stop(0%, #27aae1),
    color-stop(100%, #fcee1e)
  );
  padding-top: 09px;
  max-height: 66px;
}

#navbar-superior .navbar {
  margin-bottom: 0;
  z-index: 9999;
}

#navbar-superior .navbar-light .navbar-brand {
  font-size: 0px;
  padding-top: 0;
  padding-bottom: 0;
}

#navbar-superior .navbar-light .navbar-brand,
#navbar-superior .navbar-light .navbar-nav .nav-link {
  color: #fff;
}

#navbar-superior .navbar-light .navbar-brand {
  font-size: 0px;
  /* padding: 5px 15px; */
}

#navbar-superior .navbar-light .navbar-brand img {
  width: 80%;
}

#navbar-superior .nav-item {
  margin: auto 0;
  z-index: 99999;
}

#navbar-superior .form-control {
  background-color: #34b0ae;
  border: 0px;
  filter: progid:DXImageTransform.Microsoft.gradient( GradientType=1, startColorstr=#34b0ae, endColorstr=#60bc84);
  background-image: linear-gradient(to right, #34b0ae 0%, #60bc84 100%);
  background-image: -o-linear-gradient(to right, #34b0ae 0%, #60bc84 100%);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    right bottom,
    color-stop(0%, #34b0ae),
    color-stop(100%, #60bc84)
  );
}

#navbar-superior .input-group-addon {
  color: #fff;
}

#navbar-superior .notification {
  background-color: #d01319;
  color: white;
  position: absolute;
  border-radius: 55px;
  top: 39px;
  left: 40px;
  padding: 6px;
  line-height: 9px;
  width: 19px;
  font-size: 13px;
  visibility: hidden;
}

#objetivos-interesses,
#objetivos-personalizar {
  margin-top: 50px;
  margin-bottom: 50px;
}

.bg-verde {
  background-color: #60bc84;
}

.dropdown-size {
  width: 350px !important;
}

.notifications {
  margin-top: 5px;
}

.notifications p {
  color: #000 !important;
  font-size: 0.9em !important;
}

.nav-thin-m {
  margin-top: -24px;
}

.search {
  width: 100%;
}

.form-inline .input-group {
  width: auto;
}

@media (min-width: 991px) {
  .form-large {
    width: 65%;
  }
  .large-logo-input {
    width: 65%;
  }
  .form-inline .input-group {
    width: auto;
  }
  .nav-thin-m {
    margin-top: -24px;
  }
  #curso .navbar {
    margin-bottom: -10px !important;
  }
}

@media (max-width: 990px) {
  .form-large {
    width: 70%;
  }
  .large-logo-input {
    width: 100%;
  }
  .form-inline .input-group {
    width: none;
  }
  .nav-thin-m {
    margin-top: -6px;
  }
}

@media (max-width: 835px) {
  .large-logo-input {
    width: 100%;
  }
  .form-large {
    width: 60%;
  }
  .form-inline .input-group {
    width: none;
  }
}

@media (min-width: 576px) {
  .form-inline .input-group {
    width: 100% !important;
  }
}

.search-bar-header {
  margin-top: 0.23rem;
}

.search-bar-header .form-inline .input-group {
  width: 70%;
}

.search-bar-header .form-control {
  border-radius: 0.8rem;
  border: 0px;
  color: white !important;
}

.search-bar-header .input-group-addon {
  border: 0px;
  border-radius: 0px 0.8rem 0.8rem 0px;
}

/* ---------------- Home ---------------- */

#navbar-home .navbar-light .navbar-brand,
#navbar-home .navbar-light .navbar-nav .nav-link {
  color: #fff;
}

.slick-arrow {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}

.slick-initialized:hover .slick-arrow {
  opacity: 1;
}

.collum-l {
  width: 20%;
}

.sobre_carousel {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
}

.sobre_carousel_center {
  background: rgba(39, 170, 225, 0.4);
}

.sobre_carousel_left {
  background: rgba(39, 170, 225, 0.4);
  padding-left: 5em;
}

.sobre_carousel_right {
  background: rgba(39, 170, 225, 0.5);
}

.sobre_carousel .over_carousel {
  color: white;
  padding-top: 2.5em;
  width: 50%;
}

.sobre_carousel .over_carousel .toLeft {
  text-align: left;
  margin-left: 0px;
  padding-left: 5em;
}

.toCenter {
  text-align: left;
  margin-left: 25%;
  text-align: center;
}

.toRight {
  text-align: right;
  margin-left: 50%;
  padding-right: 5em;
}

.sobre_carousel .conteudo p {
  color: rgba(255, 255, 255, 1);
}

.sobre_carousel .links {
  padding-top: 2em;
}

.sobre_carousel .links .btn {
  width: 150px;
}

.sobre_carousel .links button {
  background: transparent;
  color: white;
  border: 1px solid white;
}

.carousel-control-next,
.carousel-control-prev {
  width: 6%;
}

#infos-home  {
  position: relative;
}

#infos-home .carousel-indicators .active {
  background-color: #27aae1 !important;
}

#infos-home .carousel-indicators li {
  border-radius: 50%;
  background-color: #fff;
  height: 10px;
  width: 10px;
}

#infos-home .carousel-inner {
  width: 100%;
  max-height: 500px !important;
}

.cursos {
  margin-top: 10px;
  margin-bottom: 20px;
}

#home .cursos .col-12 h6 {
  font-size: 1.65em;
}

#home .cursos .col-6 h6 {
  font-size: 1.55em;
}

.cursos-andamento h3 {
  color: rgba(255, 255, 255, 1);
}

.ajusteProgress .progress {
  margin-top: 1em;
}

.ajusteProgress .progress-bar {
  height: 0.3rem;
}

.ajusteProgress .concluido {
  color: white;
  font-size: 14px;
}

.img-course {
  max-width: 100%;
  height: auto;
}

.overlay-cursos {
  position: absolute;
  top: 0px;
  margin-left: 0px;
  z-index: 1;
  height: 156.86px;
  width: 260px;
  opacity: 0;
  padding: 0.5em;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: white;
  font-size: 1em;
  transition: 0.25s ease;
  -moz-transition: 0.25s ease;
  -webkit-transition: 0.25s ease;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 0.65) 100%
  );
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.slider_title progress {
  width: 155px;
  height: 3px;
  border: 0 none;
  background: white;
}

.slider_title progress::-moz-progress-bar {
  background: #27aae1;
}

.progressoCardMeuCurso progress {
  width: 100%;
  height: 5px;
  border: 0 none;
  background: rgb(211, 211, 211);
}

.progressoCardMeuCurso::-moz-progress-bar {
  background: #27aae1;
}

/* webkit */

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .slider_title progress {
    height: 5px;
  }

  .progressoCardMeuCurso progress {
    width: 100%;
    height: 5px;
    border: 0 none;
    background: rgb(211, 211, 211);
  }
}

.slider_title progress::-webkit-progress-bar {
  background: transparent;
}

.slider_title progress::-webkit-progress-value {
  background: #27aae1;
}

.progressoCardMeuCurso progress::-webkit-progress-bar {
  background: transparent;
}

.progressoCardMeuCurso progress::-webkit-progress-value {
  background: #27aae1;
}

.overlay-cursos .actions i:hover,
.overlay-cursos .actions i.active {
  cursor: pointer;
  color: #27aae1;
}

.overlay-cursos .info,
.overlay-cursos .actions {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.overlay-cursos .info a {
  color: white;
  text-shadow: 2px 2px black;
}

.overlay-cursos .info a:hover {
  color: #27aae1;
  font-weight: bold;
}

.overlay-cursos .actions {
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.barra-progress {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.overlay-cursos .barra-progress {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.overlay-cursos:hover {
  opacity: 1;
  z-index: 99999;
  height: 156.86px;
  width: 260px;
}

.curso-andamento {
  transition: margin 0.25s ease;
  -moz-transition: margin 0.25s ease;
  -webkit-transition: margin 0.25s ease;
}

.text-curso-overlay {
  color: white !important;
  font-size: 1em;
  position: absolute;
  top: 62%;
  left: 51%;
  padding: 20px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 100%;
  padding-left: 2px;
}

.text-curso-overlay p {
  color: white !important;
  margin-bottom: 0px;
}

.text-curso-overlay p:nth-child(1) {
  font-size: 15px;
  font-weight: 500;
}

.text-curso-overlay p:nth-child(2) {
  font-size: 12px;
}

.thumb-grupos .overlay-cursos {
  height: 120px;
  width: 244px;
}

.thumb-grupos .overlay-cursos:hover {
  opacity: 1;
  z-index: 99999;
  width: 244px;
  height: 120px;
}

.slick-slide {
  width: 300px;
  margin-right: 1px;
}

.thumb-grupos-pai img {
  width: 100%;
}

.thumb-grupos-pai .slick-slide {
  width: 240px !important;
}

.container-img {
  position: relative;
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.9);
}

.centered {
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 12px;
}

#banner-section {
  background: #195776;
  color: #fff;
  height: 140px;
  margin: 20px 0;
}

.banner-section--container {
  padding: 20px 0;
}

.banner-section--home-icon {
  font-size: 72px;
}

.banner-section--informations {
  padding-left: 30px;
}

.banner-section--informations .btn-light {
  color: #27aae1;
}

.slick-prev {
  left: -20px !important;
  z-index: 1;
}

.slick-next {
  right: -6px !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: FontAwesome !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.6) !important;
  color: rgb(255, 255, 255) !important;
  height: 240px;
  padding: 25px 10px !important;
}

.curso-slider .slick-next:before,
.curso-slider .slick-prev:before {
  padding: 64px 15px 52px 10px !important;
}

.curso-slider.livro .slick-next:before,
.curso-slider.livro .slick-prev:before {
  padding: 116px 15px 101px 10px !important;
}

.curso-slider-plan .slick-next:before,
.curso-slider-plan .slick-prev:before {
  padding: 64px 15px 52px 10px !important;
}

.slick-prev:before {
  content: "\f053" !important;
  font-family: FontAwesome;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.slick-next:before {
  content: "\f054" !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.ajusteProgress .progress .progress-bar {
  background-color: #27aae1 !important;
}

/* --------- Footer Paul ------------ */

.footer-paul {
  background: #fff;
  width: 100%;
  /* height: 75px; */
  /* line-height: 60px; */
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  z-index: 99;
  margin: 0;
}

mlzero {
  margin-left: 0px !important;
  color: white;
}

.footer-paul p {
  padding-top: 20px;
  font-size: 1.3em;
}

.footer-paul span {
  color: #27aae1;
}

/* ----------- footer home ------------- */

.container-footer {
  background: #27aae1;
  color: #fff;
  margin-top: 0px;
}

.container-footer li h6 {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 1.45em;
}

.container-footer li {
  margin-bottom: 8px;
}

.container-footer li a {
  font-weight: 500;
  font-size: 1em;
}

ul {
  padding: 15px;
  list-style: none;
}

.footer-home dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0rem;
}

.redes-sociais ul {
  padding: 0px;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.border-sociais {
  padding-bottom: 0.5em;
  border-bottom: 1px solid white;
  margin-bottom: 0.5em;
}

.copyright {
  background: #000;
  font-size: 12px;
  text-align: center;
}

.copyright p {
  vertical-align: middle;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 1.15em;
}

#home .redes-sociais ul li {
  background: #fff;
  color: #27aae1;
  border-radius: 50% !important;
  padding: 0px 0px 5px 0px;
  height: 34px;
  width: 34px;
  text-align: center;
  font-size: 21px;
  margin: 0;
}

#home footer {
  margin-top: 0px;
}

.logo-footer {
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.logo-footer img {
  width: 80%;
  margin-top: 6%;
}

.home-nosAcompanhe-title {
  font-family: "Dosis", "sans-serif";
  font-weight: 100;
}

.home-nosAcompanhe {
  margin-bottom: 20px;
}

.home-nosAcompanhe h4,
.home-nosAcompanhe-title .appIcons {
  margin-bottom: 10px;
}

.home-nosAcompanhe-title .appIcons {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
}

.home-nosAcompanhe-title .appIcons .store {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-justify-content: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

.home-nosAcompanhe-title .appIcons img {
  width: 111.98px;
}

.home-nosAcompanhe-title img:nth-child(2) {
  width: 51.5%;
}

/* ---------- Curso ----------- */

#subnavbar-curso .navbar {
  background: #fff;
}

.sobreCurso h4,
.professores h4 {
  font-size: 1.2em;
}

#subnavbar-curso .nome-curso,
.color-blue,
.professores h6 {
  color: #27aae1 !important;
}

#subnavbar-curso .nome-curso {
  font-weight: 500;
  font-size: 1.1em;
}

.professores .avatar-body h6 {
  font-size: 1em;
}

.professores .avatar-body span {
  font-size: 0.8em;
}

#subnavbar-curso .d-flex {
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

#subnavbar-curso .progress .progress-bar {
  background-color: #27aae1 !important;
}

#subnavbar-curso .cursoComplete-subnavCurso {
  font-weight: 300 !important;
  padding-right: 0px;
}

.color-black {
  color: #000 !important;
}

.sobreCurso h4 {
  color: #000;
}

.sobreCurso p {
  color: #000;
  font-size: 14px;
}

#curso .navbar {
  margin-bottom: 0;
}

.pontos-comentados {
  background: #43dba2;
  height: 24px;
}

.pontos-comentados .container .avatarVideo-curso {
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}

.curso-andamento {
  margin: 2px;
  position: relative;
}

.comentario-avatar p {
  font-size: 14px;
  line-height: 1.5em;
  white-space: pre-wrap;
  word-break: break-word;
}

.comentario-avatar {
  margin-top: 0px;
}

.avatarVideo-curso .user-avatar {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin: 2px 5px;
}

.avatarVideo-curso .user-avatar img {
  vertical-align: baseline;
}

.curriculo-prof {
  color: #27aae1;
  font-size: 16px !important;
  margin-bottom: 0px;
}

#comentarios .avatar .col-2 {
  padding: 0px;
}

#comentarios .avatar-body {
  padding-left: 0px !important;
  margin-left: 10px;
  padding-right: 0px;
  padding-bottom: 0px;
}

#comentarios .avatarUser-cursoComment {
  height: 50px;
  width: 50px;
}

#comentarios .comentario-avatar {
  margin-left: 0px;
  margin-right: 0px;
}

#comentarios .comentario-avatar .usersComment-curso {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 10px;
}

.risk,
.risk-professor {
  margin-left: 25px;
  margin-right: 20px;
  padding-left: 0px;
  padding-right: 0px;
  height: auto;
}

.risk-group,
.risk-professor-group {
  margin-left: 40px;
  margin-right: 20px;
  padding-left: 0px;
  padding-right: 0px;
  height: auto;
}

.risk:before,
.risk-group:before {
  background: #27aae1;
  content: "";
  display: block;
  height: 100%;
  width: 1px;
}

.risk-professor:before,
.risk-professor-group:before {
  background: #7b68ee !important;
  content: "";
  display: block;
  height: 100%;
  width: 1px;
}

@media (max-width: 1023px) and (min-width: 768px) {
  #comentarios .usersComment-curso .btnLike {
    font-size: 13px;
    padding-right: 10px;
    padding-left: 5px;
  }
  #comentarios .usersComment-curso .btnComment {
    font-size: 13px;
    padding-left: 10px;
    padding-right: 5px;
  }
}

.content-answer {
  margin-left: 70px;
}

#curso .comentarios .avatar-body span {
  font-size: 12px;
  font-weight: 200;
}

#tabs-curso .nav-tabs {
  border-bottom: none;
}

#tabs-curso ul {
  padding: 0;
}

#tabs-curso ul i {
  margin: 3px;
}

#tabs-curso .nav-item .nav-link {
  padding: 2px 1px !important;
}

#tabs-curso .form-control {
  border: none;
  border-radius: 0;
}

#tabs-curso .nav-tabs .nav-link.active {
  border-color: #fff;
  border-top-left-radius: 0% !important;
  border-top-right-radius: 0% !important;
}

#tabs-curso .nav-tabs .nav-link {
  border-right: 10px;
  padding: 10px;
}

#tabs-curso .content-bg {
  padding: unset;
}

#tabs-curso .nav-item {
  background-color: #d9d9d9;
}

.anexo-saiba-mais-curso {
  background-color: #868e96;
  height: 40px;
  border-radius: 20px;
  width: 40px;
  margin-right: 10px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.anexo-texto-saiba-mais-curso {
  font-size: 15px;
  color: white;
  font-weight: 600;
  text-align: center;
  line-height: 40px;
}

.content-bg {
  background: #fff;
}

.fonts-10 {
  font-size: 10px !important;
}

.fonts-12 {
  font-size: 12px !important;
}

/* Curso sidemenu */

p {
  font-family: "Lato", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #000;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  margin-bottom: 40px;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

/* #sidebar {
  border-right: 3px solid rgb(229, 229, 229);
  border-bottom: 3px solid rgb(229, 229, 229);
} */

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

/* .wrapper {
    display: flex;
    align-items: stretch;
    perspective: 100%;
}
#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #ededed;
    color: #838383;
    transition: all 0.6s cubic-bezier(0.945, 0.020, 0.270, 0.665);
    transform-origin: bottom left;
}
#sidebar.active {
    margin-left: -250px;
}
#sidebar ul.components {
    background: #ededed;
}
#sidebar ul {
    padding: 0;
    margin: 0;
}
#sidebar ul p {
    color: #838383;
    padding: 10px;
}
#sidebar ul li a {
    background: #fff;
    margin: 10px 0;
    padding: 10px;
    font-size: 1.1em;
    display: block;
}
#sidebar ul li .espacoBotao a {
    margin: 0;
}
#sidebar ul li a:hover {
    color: #838383;
    background: #fff;
}
#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #838383;
    background: #fff;
    margin: 0;
} */

/* ---------------- CONTENT STYLE ------------- */

/* #content {
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
}
#sidebarCollapse {
    width: 40px;
    height: 40px;
    background: #27AAE1;
}
#sidebarCollapse span {
    width: 80%;
    height: 2px;
    margin: 0 auto;
    display: block;
    background: #fff;
}
#sidebarCollapse span:first-of-type {
    transform: rotate(45deg) translate(2px, 2px);
}
#sidebarCollapse span:nth-of-type(2) {
    opacity: 0;
}
#sidebarCollapse span:last-of-type {
    transform: rotate(-45deg) translate(1px, -1px);
}
#sidebarCollapse.active span {
    transform: none;
    opacity: 1;
    margin: 5px auto;
}
#sidebar .list-unstyled {
    padding-right: 0;
} */

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

/* @media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
        transform: none;
    }
    #sidebarCollapse span:first-of-type,
    #sidebarCollapse span:nth-of-type(2),
    #sidebarCollapse span:last-of-type {
        transform: none;
        opacity: 1;
        margin: 5px auto;
    }
    #sidebarCollapse.active span {
        margin: 0 auto;
    }
    #sidebarCollapse.active span:first-of-type {
        transform: rotate(45deg) translate(2px, 2px);
    }
    #sidebarCollapse.active span:nth-of-type(2) {
        opacity: 0;
    }
    #sidebarCollapse.active span:last-of-type {
        transform: rotate(-45deg) translate(1px, -1px);
    }
} */

/* --------- Carreira ---------- */

#objetivos-carreira,
#objetivos-interesses,
#objetivos-personalizar {
  background: #fff;
  padding: 20px 0;
}

#objetivos-carreira--card-carreira,
#objetivos-carreira--desc-interesses,
#objetivos-carreira--desc-personalizar {
  margin: 20px;
}

#objetivos-carreira-botoes .btn {
  white-space: normal;
  height: 150px;
}

#objetivos-carreira--desc-usuario,
#objetivos-interesses--desc-usuario,
.objetivos-personalizar--desc-usuario {
  margin: 20px;
}

#objetivos-personalizar .objetivos-personalizar--desc-usuario h5:first-child {
  font-size: 1.5em !important;
  margin-bottom: 15px;
}

#objetivos-personalizar .objetivos-personalizar--desc-usuario .blue {
  font-weight: 450;
}

#objetivos-interesses--desc-usuario h6 {
  font-size: 1.4em;
}

#objetivos-interesses--desc-usuario p {
  text-align: center;
  margin-top: 15px;
}

.objetivos-personalizar--desc-usuario p {
  margin-bottom: 0px;
  color: #58595b;
}

.objetivos-personalizar--desc-usuario p:nth-child(3),
p:nth-child(4) {
  margin-bottom: 15px;
}

#objetivos-carreira--desc-usuario p,
#objetivos-interesses--desc-usuario p,
.objetivos-personalizar--desc-usuario p {
  font-size: 1rem;
}

/* Interesses */

#objetivos-personalizar .textareaTitle span {
  margin-right: 5px;
}

#objetivos-personalizar .textareaTitle,
.textareaField {
  margin-bottom: 5px;
}

#objetivos-personalizar .col-10 textarea {
  padding: 0.3em 0.7em;
  border: none;
  border-radius: 0px;
  background: transparent;
  outline: none;
  background-color: #f3f2f1;
}

#carreira .card {
  border-radius: 0;
}

#selecionar-carreira .btn-blue {
  font-size: 1.2em;
}

#info-carreira p {
  font-size: 12px;
  margin: 20px 0;
}

.interesses-space {
  margin: 10px 5px;
}

.grupo-interesse {
  margin-bottom: 20px;
}

.grupo-interesse label {
  font-size: 1em;
  font-weight: 400;
}

.grupo-interesse .btn {
  color: #6d6e71;
}

.grupo-interesse .btn.active,
.grupo-interesse .btn:active {
  background: #43dba2;
  border-color: #43dba2;
  color: #fff;
}

#interesses .btn .badge {
  top: -10px;
  background: #fff;
  color: #43dba2;
  border-radius: 50%;
}

#instrucao-personalizar {
  font-size: 0.9em;
  color: #58595b;
}

/* Personalizar */

.card {
  border: none;
}

#card-personalizar--infos-secondary .form-check {
  color: #999;
}

#card-personalizar--mini-cards p {
  font-size: 15px !important;
  color: #58595b;
  font-weight: 400;
}

#card-personalizar--mini-cards span {
  font-size: 45px;
  background: #fff;
  color: #27aae1;
  border-radius: 50%;
  padding: 26px 34px;
  border: 1px #eee solid;
  width: 100px;
  height: 100px;
  line-height: normal;
  -webkit-align-content: middle;
      -ms-flex-line-pack: middle;
          align-content: middle;
  word-wrap: normal;
}

.radi {
  padding: 24px 39px !important;
}

@media (max-width: 1200px) {
  .regras-card {
    padding-top: 2em;
  }
  .bg-card {
    height: 196px !important;
  }
}

.card-personalizar--card-text {
  margin-top: 16px;
  padding-left: 0px;
}

.card-text {
  font-size: 14px !important;
}

.first {
  margin-top: 0px !important;
}

.third {
  margin-top: 0px !important;
}

#selecionar-personalizar {
  padding: 10px 0;
}

#selecionar-personalizar .col-4 p {
  margin-bottom: 2px;
}

#selecionar-personalizar .col-4 .analysis {
  color: #54c3a0;
  font-size: 1.5em;
}

#selecionar-personalizar .interest-saveSend .btnSend-interest {
  width: 6em;
  margin-right: 30px;
}

#selecionar-personalizar .interest-saveSend .btnSave-interest {
  width: 9em;
  margin-right: 15px;
}

#card-personalizar--infos-primary p,
#card-personalizar--infos-secondary p {
  font-size: 14px !important;
}

#card-personalizar--infos-secondary span {
  font-weight: 400;
  color: #7a7a7a;
}

.blue {
  color: #27aae1 !important;
  font-size: 1em;
  font-weight: 600;
}

.bg-card {
  background: #e6e9ec;
  height: 100%;
  padding: 10px;
}

.back-empty {
  background: none !important;
}

.bg-card div {
  -webkit-align-items: middle;
      -ms-flex-align: middle;
          align-items: middle;
}

.bg-card p {
  font-size: 1em !important;
}

#analisando {
  background: #fff;
}

.analiseConcluida {
  background: #fff;
}

.personalite-results {
  margin-top: 20px;
}

.card-personalite {
  padding-bottom: 20px;
}

.personalite-card-one {
  padding: 20px;
}

.h-interesses {
  color: #27aae1;
  font-weight: 600;
}

.marginb-10 {
  margin-bottom: 10px !important;
}

.margint-10 {
  margin-top: 10px !important;
}

.center {
  margin: 0 auto !important;
}

/* ----------- Chat Watson ------------ */

#watsonChatPage {
  overflow: hidden;
}

.respostaWatson {
  margin: 10px 0;
}

.respostaWatson__logo {
  width: 45px;
  height: 45px;
  vertical-align: top;
}

.respostaWatson span {
  display: inline-block;
  max-width: 80%;
  width: auto;
  background-color: #27aae1;
  margin-left: 10px;
  padding: 15px;
  font-size: 15px;
  border-top-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #fff;
  box-shadow: 0 1px 0.5px #00000040;
}

.respostaWatsonListaConteudo {
  margin: 10px 0 10px 55px;
  padding: 0;
  list-style-type: none;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.respostaWatsonListaConteudo li {
  vertical-align: middle;
  width: 25%;
  margin-left: 5px;
  border: 1px solid #797979;
}

.respostaWatsonListaConteudo li.respostaWatsonListaConteudoArrow {
  width: 5px;
  font-size: 15px;
  border: none;
  padding: 5px;
  margin: 0;
  margin-right: 5px;
}

.respostaWatsonListaConteudo li img {
  display: block;
  width: 100%;
  height: auto;
}

.respostaWatsonListaConteudo p {
  margin: 5px;
  padding: 0;
}

.respostaWatsonListaConteudo .infomacao {
  font-size: 13px;
  color: #333333;
  text-overflow: ellipsis;
  width: 100%;
}

.respostaWatsonListaConteudo .titulo {
  font-size: 15px;
  color: #333333;
  font-weight: bold;
  text-overflow: ellipsis;
}

.perguntaWatson {
  margin: 10px 0;
}

.perguntaWatson span {
  display: inline-block;
  max-width: 80%;
  width: 50%;
  background-color: #f3f3f2;
  padding: 15px;
  font-size: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #333333;
  word-break: break-all;
  box-shadow: 0 1px 0.5px #00000040;
}

.perguntaWatson__image {
  height: 40px;
  margin-left: 20px;
}

.asideChatWatson {
  text-align: center;
  border-left: 1px solid #d7d7d7;
  padding: 15px;
  background-color: #eeeeee;
}

.asideChatWatson__recomendacoesTitulo {
  margin-top: 20px;
}

.asideChatWatson__divisoria {
  margin: 0 -15px 0 -15px;
  border: 0;
  border-top: 1px solid rgb(146, 148, 150);
}

.asideChatWatson > img {
  width: 100%;
  max-width: 250px;
}

.chatWatsonUltimasRecomendacoes {
  list-style-type: none;
  text-align: left;
  padding: 0 15px;
  margin: 0 -15px;
  height: calc(100vh - 10%);
  margin-bottom: 15px;
  overflow-y: auto;
}

.chatWatsonUltimasRecomendacoes::-webkit-scrollbar {
  width: 0px !important;
}

.chatWatsonUltimasRecomendacoes img {
  display: inline-block;
  width: 85%;
}

.informacoesAsideRecomendacoes {
  display: inline-block;
}

.chatWatsonUltimasRecomendacoes p {
  font-size: 15px;
  padding: 0;
  margin-bottom: 5px;
}

.chatWatsonUltimasRecomendacoes li {
  margin-bottom: 15px;
  color: #939496;
}

.chat-watson-recomendacao__assunto {
  border: none;
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding: 10px 15px;
  margin: 0 -15px;
}

.chatWatsonBox {
  height: calc(100vh - 9rem);
  overflow-y: auto;
  overflow-x: hidden;
}

.chat-paul-standby + * {
  margin-top: 125px;
}

/*.chatWatsonBox::-webkit-scrollbar {
  width: 0 !important;
}*/

.chatWatson {
  background: #fff;
  padding-right: 0;
}

.chat-watson-recomendacao__assunto label {
  margin: 0;
}

.enviarPerguntaWatson {
  position: fixed;
  width: 100%;
  /* Alterar para 75% quando recomendações estiver pronto */
  background: #414042;
  padding: 20px;
  bottom: 0;
  z-index: 1;
}

.enviarPerguntaWatson textarea {
  width: 90%;
  color: #eee;
  border-color: #666;
  background-color: #373639;
  display: inline-block;
  vertical-align: middle;
  height: 1.65rem;
  padding-left: 15px;
  border-radius: 0.45em;
  margin: 0;
  outline: none;
}

.enviarPerguntaWatson textarea::-webkit-scrollbar {
  display: none;
}

.enviarPerguntaWatsonButton {
  background-color: #27aae1;
  color: white;
  padding: 1px 10px;
  border: none;
  font-weight: 600;
  width: 80px;
  font-size: 16px;
  border-radius: 0.45em;
  margin-left: 10px;
}

.watson_avaliar-mensagem {
  font-weight: 600;
  margin-bottom: 0px;
}

.watson_opcao-avaliacao--positiva,
.watson_opcao-avaliacao--negativa {
  margin-left: 10px;
}

.watson_avaliacao button {
  text-decoration: none;
  padding: none;
  border: none;
  background: none;
  color: white;
  outline: none;
}

.watson_avaliacao .watson_opcao-avaliacao--positiva:hover,
.watson_avaliacao .watson_opcao-avaliacao--positiva-selected {
  outline: none;
  cursor: pointer;
  color: #d9e44e;
}

.watson_avaliacao .watson_opcao-avaliacao--negativa:hover,
.watson_avaliacao .watson_opcao-avaliacao--negativa-selected {
  cursor: pointer;
  color: #ffc48d;
  outline: none;
}

/* Perfil aluno */

#perfil {
  padding-top: 80px !important;
}

.avatar-photo-perfil img {
  height: 100px;
  width: 100px;
}

.editPerfil-selectFile {
  color: #27aae1;
  margin-top: 15px;
  border: 0px;
  background-color: none;
  cursor: pointer;
}

.avatar-photo-perfil label:hover {
  text-decoration: underline;
}

#myTab .nav-item {
  background-color: #d9d9d9;
}

#myTab .nav-item a {
  height: 2.5rem;
}

#file_input_id {
  display: none;
}

.bg-white-perfil {
  background: #fff;
  margin-bottom: 10px;
  padding: 10px;
}

.perfil-infos h6 {
  margin-top: 15px;
  color: #27aae1;
  font-weight: 200;
  font-size: 15px;
}

.perfil-infos p {
  color: #000;
}

#perfil-infos .avatar-photo-perfil img,
#perfil-infos .perfil-infos img {
  height: 100px !important;
  width: 100px !important;
  max-height: 100px;
  max-width: 100px;
}

#left-side-bar .redes-sociais i {
  margin: 4px;
  font-size: 24px;
}

.progresso-perfil {
  margin-top: 5px;
  margin-bottom: 10px;
}

.progresso-perfil span {
  color: #27aae1;
  font-size: 15px;
}

.progresso-perfil p {
  display: inline-block;
  font-size: 15px;
  color: #000;
}

.progresso-perfil .progress-bar {
  background-color: #27aae1;
}

#perfil .nav-tabs {
  border-bottom: 0;
}

#perfil ul {
  padding: 0;
}

#perfil .nav-tabs .nav-link.active {
  border-color: #fff;
  color: #27aae1;
  font-weight: 400;
  font-size: 0.85em;
}

#perfil .nav-tabs .nav-link i {
  background-color: #868e96;
}

#perfil .nav-tabs .nav-link.active i {
  background-color: #27aae1;
}

#perfil .nav-tabs .nav-link {
  border-radius: 0;
  font-size: 0.85em;
}

#sobre-usuario p,
#cargo-usuario p {
  color: #6d6e71;
  font-size: 14px;
}

#sobre-usuario .col-6 h5 {
  padding-top: 10px;
  padding-bottom: 10px;
}

#especialidades .col-6 h5,
#sobre-usuario .col-6 h5,
#cargo-usuario .col-6 h5 {
  font-size: 1.5em;
  margin-bottom: 5px;
}

#cargo-usuario .col-12 h5 {
  padding-top: 15px;
  font-size: 1.4em;
}

#cargo-usuario .col-12 span {
  padding-top: 5px;
  font-size: 1.2em;
}

#planos .col-12 .current-plan {
  font-size: 1.2em;
}

#historico .col-12 h5,
#trilhas .col-12 h5 {
  font-size: 1.3em;
}

#planos .col-12 .my-plan {
  font-size: 1.5em;
}

#historico td {
  font-size: 1.1em;
}

.trilhas-perfil p {
  color: #68686a;
  font-size: 14px;
}

.perfil-infos {
  background: #fff;
  padding: 20px;
  margin-top: 0px;
}

.perfil-empresa {
  font-weight: 500 !important;
  font-size: 20px;
}

.spanUserRole-perfil {
  font-size: 18px;
  font-weight: 300;
}

/* Width 100000px para ocupar todo o container */

.bgArea-perfilAluno {
  background-color: #f6f6f6;
  padding-left: 30px;
  margin-left: -15px !important;
  padding-right: 30px;
  margin-right: -15px !important;
  width: 100000px;
}

.cargo-usuario--container::after {
  content: "";
  width: 80%;
  height: 1px;
  background: #939393;
  display: block;
  margin: 8px auto;
}

#cargo-usuario .container-fluid .lastExperience-cargo::after {
  height: 0px;
}

.titutlo-historico {
  padding: 20px 0;
}

#right-side-bar .bg-grey .col > i {
  font-size: 2.2em;
}

#right-side-bar .bg-grey {
  text-align: center;
}

#right-side-bar .col h5,
#right-side-bar .col h5 {
  text-align: left;
  margin: 0px;
  font-size: 1.4em;
}

@media (max-width: 1199px) {
  #right-side-bar {
    margin: 0px;
    max-width: 8.5rem !important;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #right-side-bar .container-fluid {
    margin: 0px;
    padding: 0px;
    width: 6.429rem;
  }
}

#perfil--perfis-similares .avatar img,
#grupos-participa .avatar img {
  height: 70px !important;
  width: 70px !important;
  max-height: 70px;
  max-width: 70px;
}

#perfil--perfis-similares h6,
#avatarPerfilNetwork h6,
#grupos-participa h6 {
  color: #27aae1;
  font-size: 1.1em;
}

#perfil--perfis-similares span,
#avatarPerfilNetwork span,
#grupos-participa span {
  color: #b0b0b1;
  font-weight: 200;
  font-size: 1.1em;
}

#grupos-participa .avatar .avatar-body {
  padding-top: 0px;
}

#grupos-participa .avatar a {
  padding-top: 20px;
}

#grupos-participa .avatar .avatar-body span {
  padding-left: 15px;
}

@media (max-width: 1200px) {
  .hidden-descrip {
    display: none !important;
  }
  #grupos-participa .avatar img {
    margin-left: 21%;
  }
}

#perfil--perfis-similares {
  margin-bottom: 0;
}

#perfil-infos .redes-sociais li {
  background: #b1b1b2;
  color: #fff;
  border-radius: 50%;
  margin: 0 5px;
  padding: 0 auto;
  height: 40px;
  width: 40px;
  text-align: center;
  font-size: 20px;
}

#perfil-infos .redes-sociais li a {
  text-align: center;
}

#perfil-infos .redes-sociais li a i {
  font-size: 25px;
  padding-top: 8px;
}

.paddingSide .col-4 {
  padding-left: 6px !important;
  background-color: black;
}

#perfil-infos .col-12 h6 {
  font-size: 1.5em;
  font-weight: 350;
}

#perfil-infos .col-12 h5 {
  font-size: 1.5em;
}

#perfil-infos .col-12 p {
  font-size: 1em;
  font-weight: 100;
}

.perfil-infos .d-flex {
  margin-top: 10px;
  margin-bottom: 10px;
}

.perfil--foto {
  height: 200px;
  margin: 0 auto;
  width: 200px;
}

.perfil--foto img {
  border-radius: 50%;
  height: 100px;
  margin-bottom: 15px;
  width: 100px;
}

#grupos-participa {
  margin-top: 0px;
}

#historico .table-striped tbody tr:nth-of-type(odd) {
  background-color: #fbfbfb;
}

#headingOne .col-6 h5 {
  font-size: 1.1em;
}

#headingOne .row {
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

#left-side-bar .perfil--foto p,
#left-side-bar .perfil--foto span {
  color: #000;
}

#left-side-bar .perfil--foto span {
  font-size: 14px;
}

#left-side-bar .redes-sociais i {
  margin: 4px;
  font-size: 20px;
}

#especialidades i {
  color: #e6e9ec;
  background: #27aae1;
  border-radius: 50%;
  font-size: 1.1em;
  padding: 2px;
}

body,
html,
body > div[class*="bs-tooltip-"] {
  /* background-color: transparent !important; */
  height: unset;
}

body > div[class*="MuiTooltip-popper"] {
  font-family: "Lato", sans-serif !important;
  background-color: transparent;
  height: unset;
}

.tooltip-inner {
  max-width: 250px;
  /* padding: 3px 8px !important; */
  color: #fff !important;
  text-align: center !important;
  background-color: #27aae1 !important;
  font-size: 14px !important;
  border-radius: 0.25rem !important;
  z-index: 999999 !important;
  border: 1px solid #aaaaaa;
}
.__react_component_tooltip.type-dark.tooltip-inner.place-top::after {
  border-top-color: #aaaaaa !important;
}
.__react_component_tooltip.type-dark.tooltip-inner.place-bottom::after {
  border-bottom-color: #aaaaaa !important;
}
.__react_component_tooltip.type-dark.tooltip-inner.place-right::after {
  border-right-color: #aaaaaa !important;
}
.__react_component_tooltip.type-dark.tooltip-inner.place-left::after {
  border-left-color: #aaaaaa !important;
}

.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  border-top-color: #27aae1 !important;
}

.tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip.bs-tooltip-right .arrow::before {
  border-right-color: #27aae1 !important;
}

.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #27aae1 !important;
}

.tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip.bs-tooltip-left .arrow::before {
  border-left-color: #27aae1 !important;
}

.nav-tabs a {
  font-size: 12px;
}

.nav-tabs .nav-link i {
  color: #fff;
  background-color: #868e96;
  border-radius: 50%;
  font-size: 14px;
  padding: 5px 7px;
}

.nav-tabs .nav-link.active i {
  color: #fff;
  background: #27aae1;
  border-radius: 50%;
  font-size: 14px;
  padding: 5px 7px;
}

#cursos-concluidos i {
  color: #27aae1;
  font-size: 24px;
}

#cursos-concluidos p {
  color: #6d6e71;
  font-size: 14px;
}

#cursos-concluidos .btn-link {
  color: #27aae1;
  font-size: 15px;
}

#cursos-concluidos h5,
#historico h5,
#planos h5,
#trilhas h5 {
  margin: 15px 0;
}

#cursos-concluidos .container-fluid h5 {
  margin-bottom: 15px;
}

#trilhas .progress .progress-bar {
  background-color: #27aae1 !important;
}

.ajusteProgress .progress .progress-bar {
  background-color: #27aae1 !important;
}

/* Social */

#social,
#grupo {
  padding-top: 80px !important;
}

#social-publicacao .avatar-body span {
  font-size: 12px;
}

#social .bg-white-perfil .comentarios .btn-icon {
  padding-left: 10px;
  padding-right: 10px;
}

#social .bg-white-perfil .postComment-socialGroup .notFirstChild,
#social .bg-white-perfil .postComment-socialGroup .comentarios-reply {
  margin-top: -15px !important;
}

#social .redes-sociais li {
  padding-top: 3px;
}

.sugestoes p {
  font-size: 0.8em !important;
  line-height: 1.1em !important;
}

.avatar-social h6 {
  color: #27aae1;
  font-weight: 700;
}

.quote {
  color: #969697;
  font-weight: 400;
}

.quote p {
  font-size: 0.9em;
}

.cover img {
  height: 300px;
  width: 100%;
}

.social h5 {
  color: #27aae1;
}

.social-publicacao--descricao-container p {
  color: #000;
  font-size: 14px;
  line-height: 1.5em;
  white-space: pre-wrap;
  word-break: break-word;
}

.social-publicacao--img-container img {
  height: 250px;
  width: 100%;
}

.post-grupo {
  border: 1px solid #eee;
  margin: 10px 0;
  padding: 15px;
}

.post-grupo .avatar h6 {
  color: #27aae1;
}

.post-grupo .avatar h6 > span {
  color: #000;
  font-size: 18px;
}

.post-grupo .avatar span {
  color: #b0b0b1;
}

.banner-social {
  height: 300px;
  width: 100%;
}

#grupo .comentarios-reply {
  margin-left: 0px;
}

#timeline h6 {
  color: #27aae1;
}

#timeline a:hover {
  color: #27aae1;
  text-decoration: underline;
}

#timeline .avatar img {
  cursor: default;
}

.group-card {
  border: 1px solid #e6e9ec;
  margin: 0 auto;
}

.margin-0 {
  margin: 0 auto;
}

.margint-15 {
  margin-top: 15px !important;
}

.marginr {
  margin-right: 22px;
}

.btn-social {
  font-size: 10px;
  margin: 0 auto;
  text-align: center;
}

.marginl-20 {
  margin-left: 20px;
}

.margint-20 {
  margin-top: 20px !important;
}

/* -------- sidebar ----------- */

.wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-perspective: 100%;
          perspective: 100%;
}

#sidebar {
  background: #FFFFFF;
  color: #838383;
  transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 5px 0 10px rgba(0,0,0,.1);
}

#sidebar ul.components {
  background: #ededed;
  border-bottom: 1px solid #000;
}

#sidebar ul {
  padding: 0;
  margin: 0;
}

#sidebar ul p {
  color: #838383;
  padding: 10px;
}

#sidebar ul li a {
  background: #fff;
  margin: 10px 0;
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

#sidebar .list-group:not(:last-child) {
  margin-bottom: 13px;
}

#sidebar .list-group-item,
#sidebar .list-group-item_sub2 {
  font-size: 15px;
  border-radius: 0;
  background-color: #fff;
  color: #333;
  border-left: 0;
  border-right: 0;
  border-color: #eee;
  white-space: normal;
  border-top: 1px solid #eee;
  margin-bottom: -5px;
  transition: background 0.2s ease-in-out;
}

#sidebar .list-group-item_sub2 {
  border-top: 0;
  list-style: none;
}

.conteudo-disciplina .list-group-item_sub2:hover,
.conteudo-disciplina .list-group-item_sub2.active {
  background-color: #d7f0fc !important;
  color: #27aae1 !important;
  cursor: pointer;
}

.conteudo-disciplina .list-group-item_sub2.active {
  font-weight: bold;
}

.titulo-disciplina__second-result {
  font-size: 75%;
}

.teste-nivelamento {
  background: #fff;
  border-top: 1px solid #eee;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 15px 0;
}

.conteudo-disciplina {
  color: #6d6e71;
  font-weight: 400;
}

/* .conteudo-disciplina span {
  color: #d6d6d7;
} */

.conteudo-disciplina .list-group-item_sub2 {
  background: #f8f8fa !important;
}

/* .conteudo-disciplina .list-group-item_sub2 i {
  color: #6d6e71;
  font-size: 25px;
} */

.round label {
  /* margin-top: 5px; */
  background-color: transparent;
  border: 2px solid #b9b9b9;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.round {
  height: 20px;
  width: 20px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 5px;
  /* left: 5px; */
  opacity: 0;
  position: absolute;
  /* top: 5px; */
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  width: 10px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #27aae1;
  border-color: #27aae1;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
  margin-top: 5px;
  margin-left: 2.5px;
}

/* ---------------- Menu hamburguer ------------- */

#content {
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
}

#sidebarCollapse {
  width: 40px;
  height: 40px;
  background: #fff;
}

#sidebarCollapse span {
  width: 80%;
  height: 2px;
  margin: 0 auto;
  display: block;
  background: #27aae1;
}

#sidebarCollapse span:first-of-type {
  -webkit-transform: rotate(45deg) translate(2px, 2px);
          transform: rotate(45deg) translate(2px, 2px);
}

#sidebarCollapse span:nth-of-type(2) {
  opacity: 0;
}

#sidebarCollapse span:last-of-type {
  -webkit-transform: rotate(-45deg) translate(1px, -1px);
          transform: rotate(-45deg) translate(1px, -1px);
}

#sidebarCollapse.active span {
  -webkit-transform: none;
          transform: none;
  opacity: 1;
  margin: 5px auto;
}

#sidebar .list-unstyled {
  padding-right: 0;
}

.YouClass {
  background: red !important;
}

/* -------- MEDIAQUERIES -------------- */

/* @media (min-width: 768px) {
  #pagamento .informacoes::after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    top: 99%;
    border: 20px solid transparent;
    border-top: 20px solid hsl(198, 76%, 52%);
    z-index: 2;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
} */

@media (max-width: 768px) {
  .btn-white.pagamento {
    padding-left: 10px;
    padding-right: 10px;
  }
  #pagamento--resultado-pagamento .valor-desc {
    font-size: 3.5rem;
  }
  #pagamento .navbar {
    margin-bottom: -1px;
  }
  /* #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
        transform: none;
    } */
  #sidebarCollapse span:first-of-type,
  #sidebarCollapse span:nth-of-type(2),
  #sidebarCollapse span:last-of-type {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
    margin: 5px auto;
  }
  #sidebarCollapse.active span {
    margin: 0 auto;
  }
  #sidebarCollapse.active span:first-of-type {
    -webkit-transform: rotate(45deg) translate(2px, 2px);
            transform: rotate(45deg) translate(2px, 2px);
  }
  #sidebarCollapse.active span:nth-of-type(2) {
    opacity: 0;
  }
  #sidebarCollapse.active span:last-of-type {
    -webkit-transform: rotate(-45deg) translate(1px, -1px);
            transform: rotate(-45deg) translate(1px, -1px);
  }
}

/* highlight active menu */

#sidebar .list-group-item:not(.collapsed) {
  background-color: #fff;
}

/* closed state */

#sidebar .item-disciplina {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
}

#sidebar .item-disciplina[aria-expanded="false"]::after {
  content: " \f107";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 5px;
}

#sidebar .list-group-item[aria-expanded="false"]::after {
  content: " \f107";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}

.titulo-disciplina {
  color: #000;
  font-weight: 600;
  font-size: 15px;
  text-align: left;
  padding-right: 3px;
}

/* open state */

#sidebar .list-group .list-group-item[aria-expanded="true"] {
  background-color: #f8f8fa;
  color: #000 !important;
  font-weight: 500;
}

#sidebar .bg-titulo-disciplina {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
}

#sidebar .bg-titulo-disciplina[aria-expanded="true"] {
  background: #d7f0fc !important;
  border-color: #d7f0fc !important;
}

#sidebar .bg-titulo-disciplina[aria-expanded="true"] p {
  color: #27aae1 !important;
  font-weight: 600 !important;
}

#sidebar .item-disciplina[aria-expanded="true"]::after {
  content: " \f106";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 5px;
}
#sidebar .list-group-item[aria-expanded="true"]::after {
  content: " \f106";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 10px;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}

/* level 1 */

.list-group-item {
  padding: 0.5rem 0.75rem;
}

#sidebar .list-group .collapse .list-group-item {
  padding-left: 20px;
}

/* level 2 */

#sidebar .list-group .collapse > .collapse .list-group-item {
  padding-left: 30px;
}

/* level 3 */

#sidebar .list-group .collapse > .collapse > .collapse .list-group-item {
  padding-left: 40px;
}

@media (max-width: 48em) {
  #sidebar .list-group .collapse.in,
  #sidebar .list-group .collapsing {
    position: absolute;
    z-index: 1;
    width: 190px;
  }
  #sidebar .list-group > .list-group-item {
    text-align: center;
    padding: 0.75rem 0.5rem;
  }
}

@media (max-width: 1100px) {
  .titulo-disciplina {
    color: #000;
    font-weight: 600;
    font-size: 15px;
  }
  .btn-nivelamento {
    width: 90%;
    background: #27aae1;
    color: #fff;
    font-size: 12px;
  }
  .content-title {
    font-size: 12px;
  }
  .round label {
    left: 12px;
  }
}

@media (max-width: 48em) {
  /* overlay sub levels on small screens */
  #sidebar .list-group .collapse.in,
  #sidebar .list-group .collapsing {
    position: absolute;
    z-index: 1;
    width: 190px;
  }
  #sidebar .list-group > .list-group-item {
    text-align: center;
    padding: 0.75rem 0.5rem;
  }
  /* hide caret icons of top level when collapsed */
  #sidebar .list-group > .list-group-item[aria-expanded="true"]::after,
  #sidebar .list-group > .list-group-item[aria-expanded="false"]::after {
    display: none;
  }
}

/* change transition animation to width when entire sidebar is toggled */

#sidebar.collapse {
  transition-timing-function: ease;
  transition-duration: 0.2s;
}

#sidebar.collapsing {
  opacity: 0.8;
  width: 0;
  transition-timing-function: ease-in;
  transition-property: width;
}

/* Corpo do slider de curso */

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  border: none !important;
  background: #085f7f;
  padding: 0;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 10px;
  background: #085f7f;
}

/* Bolinha do slider */

input[type="range"]::-webkit-slider-thumb {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background: #43dba2;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type="range"]::-ms-track {
  width: 300px;
  height: 5px;
  /* remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  /* leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;
  /* remove default tick marks */
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #777 !important;
  border-radius: 10px !important;
}

input[type="range"]::-ms-fill-upper {
  background: #ddd !important;
  border-radius: 10px !important;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #888 !important;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #ccc !important;
}

#volume-range {
  height: 8px;
  width: 50%;
}

.volume-range input[type="range"]::-webkit-slider-thumb {
  height: 9px !important;
  width: 9px !important;
  border-radius: 50%;
  background: #43dba2;
  cursor: pointer;
  -webkit-appearance: none;
}

/* Fill - Preenchido - verde
  track - cor de baixo - azul escuro
  #43dba2 - verde de cima
  #085f7f - cor do fundo */

/* fillColor={{
    r: 67,
    g: 219,
    b: 124,
    a: 1,
  }}
  trackColor={{
    r: 8,
    g: 95,
    b: 127,
    a: 1,
  }} */

/* As linhas seguintes serão testes para */

/* a implementação de correções no front */

/* ---------------- Float Label, material-design ------------- */

.field-float-label-pattern {
  margin-bottom: 30px;
}

.label-fone {
  color: #27aae1;
  top: -1.2em;
  font-size: 1em;
  transition: all 300ms ease;
  transition-property: top, color, opacity;
  opacity: 1;
}

.field-float-label-pattern input[type="text"] {
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #a7a8a9;
}

.field-float-label-pattern input[type="text"]:focus,
input[type="tel"]:focus {
  border: none;
  border-bottom: 1px solid #27aae1;
}

.field-float-label-pattern input:invalid {
  box-shadow: none !important;
}

.field-float-label-pattern input,
label {
  font-size: 15px;
}

.field-float-label-pattern > div {
  list-style: none;
  padding: 0px;
}

.field-float-label-pattern > div {
  position: relative;
  margin-bottom: 1.3em;
}

.field-float-label-pattern input {
  padding: 0.1em 0em;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #a7a8a9;
  background: transparent;
  outline: none;
}

.field-float-label-pattern label {
  position: absolute;
  color: #aaa;
  left: 0.08em;
  top: 0em;
  transition: none;
}

.field-float-label-pattern input:focus + label {
  color: #27aae1;
  top: -1.2em;
  font-size: 1em;
  transition: all 300ms ease;
  transition-property: top, color, opacity;
  opacity: 1;
}

.field-float-label-pattern input:focus + label {
  color: #27aae1;
  top: -1.2em;
  font-size: 1em;
  transition: all 300ms ease;
  transition-property: top, color, opacity;
  opacity: 1;
}

.field-float-label-pattern input:not(:-ms-input-placeholder) + label {
  color: #27aae1;
  top: -1.4em;
  font-size: 1em;
  opacity: 1;
}

.field-float-label-pattern input:not(:placeholder-shown) + label {
  color: #27aae1;
  top: -1.4em;
  font-size: 1em;
  opacity: 1;
}

.field-float-label-pattern input:not(:-ms-input-placeholder):valid {
  border-bottom: 1px solid #27aae1;
}

.field-float-label-pattern input:not(:placeholder-shown):valid {
  border-bottom: 1px solid #27aae1;
}

.field-float-label-pattern label {
  opacity: 0;
}

.field-float-label-pattern input:focus {
  background-color: transparent;
}

.field-float-label-pattern input:focus::-webkit-input-placeholder {
  color: transparent;
}

.field-float-label-pattern input:focus:-ms-input-placeholder {
  color: transparent;
}

.field-float-label-pattern input:focus::placeholder {
  color: transparent;
}

#phone > .intl-tel-input .selected-flag {
  padding: 0 !important;
}

/* ---------------- Float Label End ------------- */

.criar-conta {
  margin-top: 5px;
  text-align: center;
}

.form-check-label {
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  color: #6d6e71;
}

.react-player {
  width: 100% !important;
  height: 100% !important;
}

.mp3-player {
  display: none;
}

.footerPaulHomePositionFixed {
  position: fixed;
  bottom: 0;
}

#navbarNavDropdown .navbar-nav {
  padding-right: 0px;
  z-index: 9999;
}

#navbarNavDropdown .nav-item a {
  font-size: 1.25em;
  /* color: #fff !important; */
}

#navbarNavDropdown .nav-item img {
  height: 38px !important;
  width: 38px !important;
  max-height: 38px;
  max-width: 38px;
}

#selectedFile {
  margin-top: 20px;
  /* max-height: 0px !important;
    max-width: 0px !important;
    padding: 0px !important;
    margin: 0px;
    border: 0px;
    visibility: hidden !important; */
}

.btnFollow-perfilProfessor,
.btnSendComment-curso,
.btnEdit-perfilAluno,
.btnSave-perfilAlunoModal {
  width: 6em;
}

.btnEdit-perfilAluno {
  width: 100%;
}

.btnSendComment-curso {
  position: relative;
  overflow: hidden;
}

.btnSendComment-curso:hover {
  background-color: #279de1;
  cursor: pointer;
}

.btnSendComment-curso input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: block;
}

.disciplinaSidebar::-webkit-scrollbar,
.comentarios::-webkit-scrollbar {
  width: 6px !important;
  background-color: transparent;
}

.disciplinaSidebar::-webkit-scrollbar-track,
.comentarios::-webkit-scrollbar-track {
  background: transparent;
}

.disciplinaSidebar::-webkit-scrollbar-thumb,
.comentarios::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
}

#cargo-usuario .btn-icon i {
  color: #27aae1;
}

#cargo-usuario .btn-icon:focus {
  outline: none;
  box-shadow: none;
}

#recibos-usuario .btn-icon i {
  color: #27aae1;
}

#recibos-usuario .btn-icon:focus {
  outline: none;
  box-shadow: none;
}

#especialidades .btn-icon i {
  background: none;
  color: #27aae1;
}

.btn-file {
  position: relative;
  overflow: hidden;
  border-radius: 0;
  color: black;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.btn-file:hover {
  color: black;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: block;
}

.navbar {
  padding: 0.2rem 1.3rem;
}

#navbar-superior .navbar-nav .fa-users {
  background-color: #fff;
  border-radius: 50%;
  color: #dfe647;
  font-size: 0.75em;
  padding-top: 2.75px;
  text-align: center;
  height: 20px;
  width: 20px;
}

#navbar-superior .navbar-nav .fa-users {
  background-color: #fff;
  border-radius: 50%;
  color: #dfe647;
  font-size: 0.75em;
  padding-top: 2.75px;
  text-align: center;
  height: 20px;
  width: 20px;
}

.no-gutters {
  margin: 0px;
  padding: 0px;
}

.quote {
  border-left: 2px solid #eee;
  margin-left: 70px;
  padding-left: 5px;
}

.no-resize {
  resize: none !important;
}

#sidebar .list-child {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

#sidebar .list-child .info {
  color: black;
  -webkit-flex: 2 1;
      -ms-flex: 2 1;
          flex: 2 1;
  margin-right: 5px;
}

#sidebar .list-child .with-icon {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex: 2 1;
      -ms-flex: 2 1;
          flex: 2 1;
}

#sidebar .list-child .with-icon p {
  font-size: 70%;
  color: rgba(0, 0, 0, 0.6);
}

#sidebar .list-child .with-icon a:first-of-type {
  margin-right: 10px;
}

#sidebar .iconDiscipline-curso {
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  vertical-align: middle;
  padding-left: 15px;
  margin-right: 5px;
}

#sidebar .nameDiscipline-curso {
  margin-left: 20px;
}

.menu-item-icon{
  background-color: #005aa4;
  color: white;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 10%;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.item-description{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/* -------------- Anotações rota Curso --------------- */

@media (min-width: 1024px) and (max-width: 1999px) {
  #areaNotes-curso {
    bottom: 75px;
    position: fixed;
    width: 15em;
    left: 105%;
    margin-left: -28.5%;
  }
}

@media (min-width: 2000px) {
  #areaNotes-curso {
    bottom: 0;
    position: fixed;
    width: 15em;
    left: 80%;
  }
}

#areaNotes-curso {
  position: fixed;
  width: 15em;
  right: 15px;
}

#areaNotes-curso .headerNotes-curso {
  padding: 5px;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 600;
  text-align: center;
  font-size: 1.5em !important;
  color: #fff;
  background-color: #58dba2;
  margin: 0px;
}

#areaNotes-curso .headerNotes-curso label {
  margin: 0px;
  padding-left: 5px;
}

#areaNotes-curso .inputNotes-curso {
  background-color: #fff;
}

#areaNotes-curso .titleNotes-curso input {
  color: #27aae1;
  font-weight: 500;
  border-bottom: 1px solid #a7a8a9;
  background-color: #fff;
}

#areaNotes-curso .txtNotes-curso textarea {
  padding-right: 0px;
  padding-left: 0px;
}

#areaNotes-curso .txtNotes-curso button {
  width: 5em;
  margin-bottom: 5px;
}

.hidden-area {
  height: 0;
  transition: height, 0.5s linear;
}

.hidden-area.open {
  height: 260px;
  transition: height, 0.5s linear;
}

.conteudo {
  font-size: 13px;
}

/* -------------------- END ------------------------- */

#myTab .nav-item .myTabSeparator {
  padding-left: 17.45px;
  margin-top: -3px !important;
  margin-left: -17.45px !important;
  border-left-width: 1px;
  border-left-color: #868e96;
  border-left-style: solid;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: -3px !important;
}

.tabCursoSeparator {
  margin-left: -2px !important;
  border-left-width: 1px;
  border-left-color: #868e96;
  border-left-style: solid;
}

/* ------------- Curso Tab: Livros -------------- */

#books-container {
  padding-top: 15px;
}

.book-container .book-row {
  margin: 0px 15px 15px 15px;
}

.book-container .book-text {
  margin-left: 10px;
}

.book-container .book-title,
.book-container .book-autor,
.book-container .book-pages {
  margin: 0px !important;
}

.book-container .book-title {
  font-size: 1em;
  margin-bottom: 5px !important;
}

.book-container .book-autor {
  font-size: 0.9em;
  color: rgb(100, 100, 100);
  line-height: 1;
  margin-bottom: 3px !important;
}

.book-container .book-pages {
  font-size: 0.8em;
  color: rgb(185, 185, 185);
  line-height: 1;
}

#livros .books-btnDiv {
  padding-bottom: 15px;
}

#livros button {
  width: 6em;
}

/* ---------------------------------------------- */

/* ------------- Curso Tab: Anexos -------------- */

#attachments-container {
  padding-top: 16px;
  padding-bottom: 1px;
}

.attachment-container .attachment-row {
  margin: 0px 15px 15px 15px;
}

.attachment-container .attachment-imgDiv,
.attachment-container .attachment-download {
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}

.attachment-container .attachment-fileName {
  margin-left: 15px;
}

.attachment-container .attachment-download {
  color: rgb(125, 125, 125);
  font-size: 1.525em;
}

/* ---------------------------------------------- */

/* ------------ Modal Social Group -------------- */

#modalGroup .modalBody-container {
  background-color: #f3f2f1;
}

#modalGroup .modal-body {
  margin: 15px;
  background-color: #fff;
}

#modalGroup .modal-body .avatar-photo-perfil input {
  margin-top: 20px;
}

#modalGroup .modal-body input,
#modalGroup .modal-body select,
#modalGroup .modal-body textarea {
  padding: 0.3em 0.7em;
  border: none;
  border-radius: 0px;
  background: transparent;
  outline: none;
  background-color: #f3f2f1;
}

#modalGroup .modal-footer button {
  border-radius: 10px;
  width: 6em;
}

/* --------------------------------------------- */

/* -------------- Modal scrollbar --------------- */

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.ReactModal__Content::-webkit-scrollbar {
  width: 0 !important;
}

/* ------ Area Professor - rota: Curso  -------- */

#areaProfessor-curso {
  margin-top: 10px;
  background-color: #fff;
  padding: 15px;
}

#areaProfessor-curso .divImgProfessor-curso {
  margin-left: 15px;
  margin-right: 10px;
}

#areaProfessor-curso .divContainerProfessor-curso {
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#areaProfessor-curso .divInfoProfessor-curso h5 {
  font-size: 1.5em;
  color: #27aae1;
}

#areaProfessor-curso .divInfoProfessor-curso span {
  color: #7a7a7a;
}

#areaProfessor-curso .divInfoProfessor-curso,
#areaProfessor-curso .divBookmarkProfessor-curso {
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}

#areaProfessor-curso .divBookmarkProfessor-curso {
  padding-right: 15px;
}

#areaProfessor-curso .divBookmarkProfessor-curso i {
  background-color: #868e96;
  color: white;
  border-radius: 50%;
  padding: 5px 7.5px;
  margin-right: 5px;
}

/* ------ Cursos com a Disciplina - rota: Curso  ------ */

#cursoContemDisciplina-curso {
  margin: 10px 0px;
  background-color: #fff;
  padding: 15px;
}

#cursoContemDisciplina-curso .divContainerCurso-curso {
  padding-top: 15px;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}

#cursoContemDisciplina-curso .divImgCurso-curso {
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  width: auto;
}

#cursoContemDisciplina-curso .divImgCurso-curso img {
  height: auto;
}

@media (max-width: 385px) {
  #cursoContemDisciplina-curso .divImgCurso-curso {
    width: 100%;
    text-align: center;
  }
  #cursoContemDisciplina-curso .divInfoCurso-curso h6 {
    font-size: 1.2em;
  }
  #cursoContemDisciplina-curso .divImgCurso-curso img {
    width: 250px;
    padding-bottom: 5px;
  }
}

@media (min-width: 385px) and (max-width: 580px) {
  #cursoContemDisciplina-curso .divImgCurso-curso {
    text-align: center;
    width: 100%;
  }
  #cursoContemDisciplina-curso .divInfoCurso-curso h6 {
    font-size: 1.2em;
  }
  #cursoContemDisciplina-curso .divImgCurso-curso img {
    padding-bottom: 5px;
  }
}

@media (min-width: 768px) and (max-width: 875px) {
  #cursoContemDisciplina-curso .divImgCurso-curso {
    text-align: center;
    width: 100%;
  }
  #cursoContemDisciplina-curso .divImgCurso-curso img {
    padding-bottom: 5px;
  }
  #cursoContemDisciplina-curso .divInfoCurso-curso h6 {
    font-size: 1.3em;
  }
}

@media (min-width: 875px) {
  #cursoContemDisciplina-curso .divImgCurso-curso img {
    max-width: 100%;
  }
}

#cursoContemDisciplina-curso .divInfoCurso-curso h6 {
  color: #27aae1;
  font-weight: 600;
}

#cursoContemDisciplina-curso .divInfo-curso span {
  color: #7a7a7a;
}

/* ------------ Overflow tab - rota: Curso ----------- */

#comentarios .comentarios {
  max-height: calc(160px + 40vh);
  overflow-y: auto;
  margin-bottom: 15px;
}

#livros .books-curso,
#anexos .books-curso,
#anexos .attachments-curso {
  max-height: calc(360px + 40vh);
  overflow-y: auto;
  margin-bottom: 15px;
}

/* --------------------------------------------------- */

.lit-modal-especialidade {
  background: transparent !important;
  padding: 0 !important;
  width: 100%;
}
.lit-modal-notas {
  background: transparent !important;
  padding: 0 !important;
  width: 100%;
  max-width: 90% !important;
}
.lit-modal-especialidade svg {
  z-index: 99;
}
.lit-modal-notas svg {
  z-index: 99;
}

.lit-modal-form {
  background: transparent !important;
  padding: 0 !important;
  max-width: 500px !important;
  width: 100%;
}
.lit-modal-cpf {
  background: transparent !important;
  padding: 0 !important;
  max-width: 500px !important;
  width: 100%;
}
.lit-modal-restrict {
  background: transparent !important;
  padding: 0 !important;
  max-width: 800px !important;
  width: 100%;
}

.lit-modal-restrict svg {
  z-index: 99;
}

.modal-all {
  width: 100% !important;
  background-color: #fff;
  margin: 0;
  padding: 0;
}

.modal-al {
  width: 100% !important;
}

.lit-perfil-form.pagamento {
  width: 100%;
  max-width: 100% !important;
}

.lit-perfil-form.pagamento .perfilEdit-field {
  padding: 2em;
}

.lit-perfil-form .modal-body p {
  color: #000;
}

.lit-modal-form svg {
  z-index: 99;
}

.lit-perfil-form {
  background: transparent !important;
  padding: 0 !important;
  width: 100%;
}

.lit-perfil-form svg {
  z-index: 99;
}

.lit-perfil-form .modal-body {
  background-color: #f3f2f1;
}

.lit-perfil-form .perfilEdit-field {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: #fff;
}

#editalPerfilModal .modal-title h5 {
  font-size: 1.4em;
}

.lit-perfil-form .perfilEdit-field h6 {
  font-size: 1.3em;
}

.lit-perfil-form .modal-body .form-group input {
  border: none;
  border-radius: 0px;
  background: transparent;
  outline: none;
  background-color: #f3f2f1;
}

.lit-perfil-form .modal-body .form-group textarea {
  padding: 0.3em 0.7em;
  border: none;
  border-radius: 0px;
  background: transparent;
  outline: none;
  background-color: #f3f2f1;
}

.lit-modal-members {
  background: transparent !important;
  padding: 0 !important;
  width: 100%;
}

.lit-modal-members .modal-body {
  padding: 0 !important;
  margin: 0 !important;
}

.lit-modal-members svg {
  z-index: 99;
}

/* ---------------- rota: VoucherMain -------------------- */

.voucherInvalid {
  color: red;
}

.titleVoucher-voucherRoteTable {
  margin-top: 50px;
  margin-bottom: 0px;
  padding-left: 30px;
  padding-top: 5px;
  padding-bottom: 10px;
  background-color: #d3d3d3;
}

.titleVoucher-voucherRoteTable h1 {
  margin-bottom: 0px;
}

#voucherRoteTable .colorGrey {
  color: #6f6f70 !important;
}

#voucherRoteTable .colorGreen {
  color: #71c158 !important;
}

#voucherRoteTable .colorRed {
  color: #e54242 !important;
}

#voucherRoteTable .bigIcon {
  font-size: 24px;
}

#voucherRoteTable .miniIcon {
  background-color: #ffffff;
  position: relative;
  border-radius: 50%;
  margin-left: -8px;
  font-size: 12px;
  height: 15px;
  width: 15px;
  padding-top: 2px;
  margin-top: 0px;
  bottom: 10px;
  top: 5px;
}

#voucherRoteTable {
  background: #fff;
  padding: 20px 0;
  font-size: 14px;
}

#voucherRoteTable .voucher-table-item {
  padding: 0.7rem 5px;
  font-size: 15px;
}

#voucherRoteTable .divContainer-voucherTable {
  padding-left: 50px;
  padding-right: 50px;
  z-index: 0;
}

#voucherRoteTable .divContainer-voucherTable #triangle-down {
  width: 0;
  height: 0;
  position: absolute;
  top: 18px;
  right: 15px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 12px solid #999999;
}

#voucherRoteTable .divContainer-voucherTable hr {
  margin-top: 10px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #ddd;
}

#voucherRoteTable .divContainer-voucherTable .noVoucher-voucherTable {
  margin-bottom: 0px;
  font-size: 25px;
  text-align: center;
  color: #666;
}

#voucherRoteTable .divContainer-voucherTable .divSearchBar-voucherTable div {
  height: 100%;
}

#voucherRoteTable
  .divContainer-voucherTable
  .divSearchBar-voucherTable
  .input-group {
  margin-bottom: 0px;
  height: 100%;
}

#voucherRoteTable
  .divContainer-voucherTable
  .divSearchBar-voucherTable
  .input-group
  input {
  background-color: #e8e8e8;
  border: 0px;
}

#voucherRoteTable .divContainer-voucherTable .divSearchBar-voucherTable button {
  border-radius: 0 !important;
}

#voucherRoteTable .divContainer-voucherTable .btnNewVoucher-voucherTable {
  background: #27aae1;
  border-color: #27aae1;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  font-size: 20px;
  padding: 3px 28px;
  max-height: 100%;
  height: 100%;
}

#voucherRoteTable .divContainer-voucherTable .btnActions-voucherTable button {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#voucherRoteTable
  .divContainer-voucherTable
  .btnActions-voucherTable
  .miniIcon {
  background-color: transparent;
  font-size: 14px;
}

#voucherRoteTable .divContainer-voucherTable .btnActions-voucherTable > div {
  padding: 0 2.5px;
}

#voucherRoteTable .divContainer-voucherTable .select-voucherTable select {
  background-color: transparent;
  border: 0px;
  outline: none;
  font-size: 28px;
  color: #999999;
  padding-left: 10px;
}

#voucherRoteTable .divContainer-voucherTable .tableHead-voucherTable {
  background-color: #27aae1;
  color: #fff;
}

#voucherRoteTable .divContainer-voucherTable .tableBody-voucherTable {
  color: #9e9e9e;
}

#voucherRoteTable .divContainer-voucherTable .btnAction-voucherTable {
  background: #27aae1;
  border-color: #27aae1;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  width: 100px;
  height: 30px;
  max-width: 100px;
  max-height: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
}

#voucherRoteTable .divContainer-voucherTable .btnActions-voucherTable i {
  font-size: 20px;
  color: #fff;
}

.btn-create {
  position: absolute;
  right: 4%;
}

/* ------------------------------------------------------------ */

/* ----------------- Pagination rota: voucher ----------------- */

#PaginationVoucher-voucher .padR20 {
  padding-right: 20px;
}

#PaginationVoucher-voucher .noTriangleColor {
  border-color: #797979;
}

#PaginationVoucher-voucher .triangle-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 10px solid #27aae1;
  border-bottom: 10px solid transparent;
}

#PaginationVoucher-voucher .triangle-right {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 10px solid #27aae1;
  border-bottom: 10px solid transparent;
}

#PaginationVoucher-voucher .navigation {
  font-size: 25px;
  color: #797979;
}

#PaginationVoucher-voucher .active {
  color: #27aae1;
}

/* ------------------------------------------------------------ */

.pdf-wrapper {
  /* max-height: 600px; */
  /* overflow-y: auto !important; */
  /* overflow-x: hidden !important; */
  width: 100%;
  height: 640px;
  overflow: hidden;
  background: white;
}

.pdfViewer .page {
  box-sizing: content-box;
}

#modal-inner {
  margin-top: 22px;
}

.testscroll {
  overflow-y: auto;
}

.btn-pdf {
  background: transparent;
  color: white;
  cursor: pointer;
}

.btn-pdf:focus,
.btn-pdf:active {
  outline: none;
  box-shadow: none;
}

.sharevideo {
  position: absolute;
  z-index: 2;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
}

.hidediv {
  display: none;
}

.video-control {
  background: #27aae1;
  color: white;
  padding: 2px;
}

.video-controls-wrapper input[type="range"] {
  display: block;
}

.video-controls-wrapper.fullscreen {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  transition: opacity 0.3s 0s ease-in-out;
}

.video-controls-wrapper.fullscreen.hidden {
  opacity: 0;
}

/* -------progress bar------ */

circularprogressbar {
  /* * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   *
   * If you're not using "display: flex", you can remove this style. */
  width: 100%;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  /* dominant-baseline: middle; */
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

/* * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbar
 *     className="CircularProgressbar-inverted"
 *     background
 *     percentage={50}
 *   /> */

.CircularProgressbar.CircularProgressbar-inverted
  .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

.grid-chart {
  width: 20%;
  float: left;
  padding: 5px 1px;
}

.trokeTradicao .CircularProgressbar .CircularProgressbar-path {
  stroke: #0cb2e3;
}

.trokeEstimulacao .CircularProgressbar .CircularProgressbar-path {
  stroke: #43dba2;
}

.trokeAjuda .CircularProgressbar .CircularProgressbar-path {
  stroke: #f8ee1e;
}

.trokeRealizacao .CircularProgressbar .CircularProgressbar-path {
  stroke: #01aded;
}

.trokeAproveita .CircularProgressbar .CircularProgressbar-path {
  stroke: #43dba2;
}

.titleProgress {
  color: #58595b;
  line-height: 15px;
  margin-top: 5px;
  word-wrap: break-word;
}

.titlePercent div {
  stroke: #58595b;
  /* font-weight: 100; */
}

.mZero {
  margin-top: 0px !important;
}

.ajuste-footer-paul {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.post-card {
  /* padding: 1em; */
  background: white;
  /* margin-bottom: 10px; */
}

/* ---------- Notificações navBarSuperior ----------- */

/* #navbarNavDropdown .bell-notificacoes{
  position: absolute;
  right: 90px;
  z-index: 1;
  top: 35px;
}

#notificacoes-navBarSuperior .nav-link{
  padding: 0px;
}

#notificacoes-navBarSuperior .fa-bell{
  font-size: 22px;
  color: #fff;
} */

#navbar-superior .redCircle-notificacoes {
  position: absolute;
  top: 38px;
  background-color: red;
  border-radius: 50%;
  right: 25px;
  width: 20px;
  height: 20px;
}

#navbar-superior .numberNotification-notificacoes {
  height: 20px;
  width: 20px;
  margin-bottom: 0px;
  text-align: center;
  color: white;
  font-size: 15px;
  font-weight: 600;
}

/* #notificacoes-navBarSuperior .dropdown-menu{
  border: transparent;
  background-color: transparent;
  width: 450px;
  margin: 0px;
  padding: 0px;
  margin-right: -11px;

} */

#notificacoes-navBarSuperior {
  position: absolute;
  z-index: 3;
  right: -60px;
  top: 45px;
}

#notificacoes-navBarSuperior .notificacoesContainer-notificacoes {
  width: 500px;
}

#notificacoes-navBarSuperior .title-notificacoes {
  background-color: #fff;
  color: #000;
  padding: 10px 0px;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  text-align: center;
}

#notificacoes-navBarSuperior .title-notificacoes h5 {
  margin-bottom: 0;
}

#notificacoes-navBarSuperior .footer-notificacoes {
  background-color: #fff;
  color: #000;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 3px 25px;
  border-bottom-left-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
}

#notificacoes-navBarSuperior .footer-notificacoes span {
  font-size: 1.2rem;
}

#notificacoes-navBarSuperior .content-notificacoes {
  background-color: #fff;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  color: #9e9e9e;
}

#notificacoes-navBarSuperior .content-notificacoes .notFirstChild {
  border-top-width: 1px;
  border-top-color: #cdcdcd;
  border-top-style: solid;
}

#notificacoes-navBarSuperior .content-notificacoes:last-child {
  border-bottom-right-radius: 0.3em;
  border-bottom-left-radius: 0.3em;
}

#notificacoes-navBarSuperior .content-notificacoes .notificacao-notificacoes {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
}

#notificacoes-navBarSuperior
  .content-notificacoes
  .notificacao-notificacoes:hover {
  background-color: #eee;
}

#notificacoes-navBarSuperior
  .content-notificacoes
  .notificacao-notificacoes:last-child:hover {
  background-color: #eee;
  border-bottom-right-radius: 0.3em;
  border-bottom-left-radius: 0.3em;
}

#notificacoes-navBarSuperior
  .content-notificacoes
  .notificacao-notificacoes
  .icon-notificacao {
  margin-left: 10px;
  padding-top: 0px;
}

#notificacoes-navBarSuperior
  .content-notificacoes
  .notificacao-notificacoes
  .icon-notificacao
  i {
  font-size: x-large;
  color: #9e9e9e;
  height: 25px;
  width: 24px;
  text-align: center;
  background-color: transparent;
}

#notificacoes-navBarSuperior
  .content-notificacoes
  .notificacao-notificacoes
  .description-notificacao {
  margin-left: 10px;
}

#notificacoes-navBarSuperior
  .content-notificacoes
  .notificacao-notificacoes
  .description-notificacao
  label {
  font-size: 16px;
  /* margin-left: 10px; */
  margin-bottom: 0px;
  font-weight: 500;
}

#notificacoes-navBarSuperior
  .content-notificacoes
  .notificacao-notificacoes
  .date-notificacao {
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

#notificacoes-navBarSuperior
  .content-notificacoes
  .notificacao-notificacoes
  .date-notificacao
  label {
  font-size: 11px;
  text-align: right;
  margin-bottom: 0px;
}

#triangle-up {
  width: 0;
  height: 0;
  margin-left: 418px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid #fff;
}

/* -------------------------------------------------- */

/* ----------------- rota: createVoucher ------------ */

.titleVoucher-createVoucher {
  margin-top: 50px;
  margin-bottom: 0px;
  padding-left: 30px;
  padding-top: 5px;
  padding-bottom: 10px;
  background-color: #d3d3d3;
}

.titleVoucher-createVoucher h1 {
  margin-bottom: 0px;
}

#createVoucher {
  background: #fff;
  padding: 20px 0;
  font-size: 18px;
}

#createVoucher .no-spin::-webkit-inner-spin-button,
#createVoucher .no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
  -moz-appearance: textfield !important;
}

#createVoucher .divContainer-createVoucher {
  padding-left: 50px;
  padding-right: 50px;
  z-index: 0;
}

#createVoucher .divContainer-createVoucher .bradescoTitle-createVoucher {
  margin-bottom: 15px;
  color: #27aae1;
}

#createVoucher .divContainer-createVoucher hr {
  margin-top: 15px;
  margin-bottom: 15px;
  border: 0;
  border-top: 1px solid #ddd;
}

#createVoucher .divContainer-createVoucher .width100 {
  width: 100%;
}

#createVoucher
  .divContainer-createVoucher
  .divCompany-createVoucher
  .labelCompany-createVoucher {
  font-size: 22px;
  font-weight: 500;
  color: #9a9a9a;
  margin-right: 20px;
  margin-bottom: 0px;
}

#createVoucher
  .divContainer-createVoucher
  .divCompany-createVoucher
  .selectCompany-createVoucher {
  width: 600px;
  background-color: #e8e8e8;
  color: #777777;
  border: 0;
  height: 100%;
  outline: none;
  padding: 8px 10px;
}

#createVoucher .divContainer-createVoucher .divInputArea-createVoucher {
  margin-bottom: 12px;
}

#createVoucher
  .divContainer-createVoucher
  .divInputArea-createVoucher
  .rowInput-createVoucher {
  margin-bottom: 10px;
}

#createVoucher
  .divContainer-createVoucher
  .divInputArea-createVoucher
  .rowInput-createVoucher
  .labelTopic-createVoucher {
  font-size: 20px;
  font-weight: 500;
  color: #9a9a9a;
  margin-bottom: 0px;
}

#createVoucher
  .divContainer-createVoucher
  .divInputArea-createVoucher
  .rowInput-createVoucher
  .data-createVoucher {
  background-color: #e8e8e8;
  color: #777777;
  border: 0;
  height: 100%;
  outline: none;
  padding: 8px 10px;
}

#createVoucher .divContainer-createVoucher .divResults-createVoucher {
  margin-top: 20px;
  margin-bottom: 20px;
}

#createVoucher
  .divContainer-createVoucher
  .divResults-createVoucher
  .labelTopic-createVoucher {
  font-weight: 500;
  color: #27aae1;
}

#createVoucher
  .divContainer-createVoucher
  .divResults-createVoucher
  .divValue-createVoucher {
  color: #999999;
  font-weight: 400;
}

#createVoucher .divContainer-createVoucher .btnGerarCupom-createVoucher {
  color: #fff;
  background-color: #27aae1;
  width: 9em;
  font-size: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 0.5em;
}

/* -------------------------------------------------- */

/* -------- AssignVoucher rota: createVoucher ------- */

#modal-assignVoucher .modal-header {
  background-color: #27aae1;
  color: #fff;
}

#modal-assignVoucher .modalHeader-assignVoucher {
  width: 100%;
}

#modal-assignVoucher .modalHeader-assignVoucher h4 {
  font-weight: 600;
}

#modal-assignVoucher .modalHeader-assignVoucher .iconHeader-assignVoucher {
  font-size: 1.5em;
  color: #fff;
  margin-right: 5px;
  margin-bottom: 8px;
  cursor: pointer;
}

#modal-assignVoucher .modalBody-assignVoucher .labelBody-assignVoucher {
  font-size: 22px;
  color: #27aae1;
  font-weight: 500;
}

#modal-assignVoucher .modalBody-assignVoucher .inputBody-assignVoucher {
  background-color: #e8e8e8;
  color: #777777;
  border: 0;
  height: 100%;
  width: 100%;
  outline: none;
  padding: 8px 10px;
}

#modal-assignVoucher .modalBody-assignVoucher .divRow-assignVoucher {
  height: 3em;
}

#modal-assignVoucher .modalBody-assignVoucher .spanBody-assignVoucher {
  color: rgb(154, 154, 154);
  font-weight: 500;
  padding-left: 20px;
}

#selectUser-assignVoucher .select-assignVoucher select {
  background-color: transparent;
  border: 0px;
  outline: none;
  font-size: 28px;
  color: #999999;
  padding-left: 10px;
}

/* -------------------------------------------------- */

.lit-editAvatar {
  float: left;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

.lit-editAvatar:hover .caption {
  opacity: 1;
}

.lit-editAvatar img {
  margin: 0px;
  padding: 0px;
  float: left;
  z-index: 4;
  border-radius: 50%;
}

.lit-editAvatar .caption {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  transition: all 0.45s ease-in-out;
}

.lit-editAvatar .blur {
  background-color: rgba(0, 0, 0, 0.65);
  height: 100px;
  width: 100px;
  border-radius: 50%;
  z-index: 5;
  position: absolute;
}

.lit-editAvatar .button-center {
  z-index: 10;
  color: #fff;
  position: absolute;
  width: 100px;
  height: 100px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.avatarCrop {
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.avatarEdit-buttons {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1em;
}

.btn-disabled {
  opacity: 0.65;
}

input[type="range"]:disabled {
  opacity: 0.65;
  cursor: default;
}

.avatarCrop.group canvas {
  max-width: 425px;
  max-height: 265px;
}

.row.groups {
  margin: 0 auto;
}

.card-img-top.group {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 180px;
}

.group-desc {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
      -ms-flex: 1 1;
          flex: 1 1;
}

.experiences.networking {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 13px;
}

.busca-grupo {
  max-height: 172px;
  float: left;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

.busca-grupo img {
  height: 172px;
  width: 100%;
  margin: 0px;
  padding: 0px;
  float: left;
  z-index: 4;
}

.busca-grupo:hover .legenda {
  opacity: 1;
}

.busca-grupo .legenda {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  transition: all 0.45s ease-in-out;
}

.busca-grupo .blur {
  background-color: rgba(0, 0, 0, 0.65);
  width: 277px;
  height: 172px;
  z-index: 5;
  position: absolute;
}

.busca-grupo .texto h1 {
  text-transform: uppercase;
  font-size: 24px;
}

.busca-grupo .texto {
  z-index: 10;
  color: #fff;
  position: absolute;
  width: 277px;
  height: 172px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 10px;
}

/* ------------- View User rota: Voucher ------------ */

#modal-viewUserVoucher .header-viewUserVoucher {
  background-color: #27aae1;
  padding-bottom: 0;
  padding-top: 10px;
}

#modal-viewUserVoucher .header-viewUserVoucher h4 {
  color: #fff;
  font-weight: 600;
}

#modal-viewUserVoucher .header-viewUserVoucher i {
  font-size: 1.5em;
  color: #fff;
  margin-right: 5px;
  margin-bottom: 8px;
  cursor: pointer;
}

#modal-viewUserVoucher #perfil-infos {
  min-height: 170px;
  height: 100%;
  background: #fff;
}

#modal-viewUserVoucher .body-viewUserVoucher .contentTrails-viewUserVoucher {
  height: 100%;
  min-height: 150px;
}

/* -------------------------------------------------- */

#search-grupo .slick-prev:before,
#search-grupo .slick-next:before {
  background: hsla(198, 76%, 52%, 0.753) !important;
  color: white !important;
}

#search-grupo .slick-prev:hover:before,
#search-grupo .slick-next:hover:before {
  background: hsla(198, 76%, 52%, 1) !important;
  color: white !important;
}

.livro-container {
  width: 25% !important;
  padding: 5px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  cursor: pointer;
}

.livro-container img {
  width: 100% !important;
  /* height: 230px; */
}

.usersComment-curso.resposta {
  margin-left: 70px !important;
}

.tag-prof {
  color: white !important;
  background: #6a5acd !important;
}

.btn-outline-lit {
  background-color: #27aae1;
  border: 1px solid #27aae1;
  color: white;
  border-radius: 10px;
}

.btn-outline-lit:hover {
  color: black;
  background-color: white;
}

.bold {
  font-weight: bold;
}

.search::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.search:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.search::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.search:focus::-webkit-input-placeholder {
  color: transparent;
}

.search:focus:-ms-input-placeholder {
  color: transparent;
}

.search:focus::placeholder {
  color: transparent;
}

.member {
  color: rgb(39, 170, 225);
  font-weight: 700;
  text-align: center;
}

.cupom {
  background: #f7f7f7;
  padding: 1em;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.cupom .form {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around;
}

.cupom .form .form-group {
  -webkit-flex-basis: 65%;
      -ms-flex-preferred-size: 65%;
          flex-basis: 65%;
}

.card-header.with-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  /* background: #e9e6e4!important; */
}

.card-header.with-flex .titulo i {
  background-color: #868e96;
  border-radius: 50%;
  padding: 5px;
  font-size: 14px;
  color: white;
}

.card-header.with-flex span.titulo {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.2em;
  font-weight: 500;
  color: black;
}

.badge-blue {
  color: rgb(39, 170, 225);
}

/* ------------ Feedback - rota: watsonChat ------------ */

#watson-chat__feedback-modal .feedback-modal__header {
  background-color: #27aae1;
  color: #fff;
  font-weight: 600;
  padding-bottom: 0;
}

img.cover-fit {
  -o-object-fit: cover;
     object-fit: cover;
}

#watson-chat__feedback-modal .feedback-modal__header > * {
  font-weight: 600;
}

#watson-chat__feedback-modal .feedback-modal__header > * {
  font-weight: 600;
}

.chat-paul-standby {
  position: fixed;
  background-color: white;
  width: 88%;
  margin-top: -15px;
}

.chat-paul-standby img {
  margin-top: 20px;
}

.card-header.trilha {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.card-header.trilha .titulo {
  font-weight: 600;
}

.card-header.trilha .actions {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.card-header.trilha .actions .angle-down {
  background: transparent;
  cursor: pointer;
  border: none;
  box-shadow: none;
}

.card-header.trilha .actions .angle-down:focus {
  box-shadow: none;
  border: none;
}

progress.trilhas {
  width: 120px;
  height: 5px;
  margin-left: 5px;
  margin-right: 2px;
  border: 0 none;
  background: white;
}

progress.trilhas::-moz-progress-bar {
  background: #27aae1;
}

/* webkit */

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  progress.trilhas {
    height: 10px;
  }
}

progress.trilhas::-webkit-progress-bar {
  background: transparent;
}

progress.trilhas::-webkit-progress-value {
  background: #27aae1;
}

#watson-chat__feedback-modal .feedback-modal__header i {
  font-size: 1.5em;
  color: #fff;
  margin-right: 5px;
  margin-bottom: 8px;
  cursor: pointer;
}

#watson-chat__feedback-modal .feedback-modal__body {
  background: #fff;
}

#watson-chat__feedback-modal .feedback-modal__body__feedback-text {
  text-align: center;
  font-size: 18px;
}

#watson-chat__feedback-modal .feedback-modal__footer {
  background: #fff;
  border-top: 1px solid #cecece;
  padding: 10px 15px;
}

#watson-chat__feedback-modal .feedback-modal__footer button {
  font-size: 16px;
  width: 8em;
  border-radius: 0.6em;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.watsonBadge-Area {
  margin-top: -25px;
}

.watsonBadge__interesses {
  height: 40px;
}

.interesse-container__no-watson-badge,
.carreira-container__no-watson-badge,
.analisando-container__no-watson-badge {
  height: calc(92vh - 12rem);
}

.interesse-container__no-watson-badge {
  min-height: 35rem;
}

.carreira-container__no-watson-badge {
  min-height: 30rem;
}

.analisando-container__no-watson-badge {
  min-height: 25rem;
}

.btn.reaction {
  color: rgba(255, 255, 255, 0.3) !important;
}

.btn.reaction.active {
  color: rgba(255, 255, 255, 1) !important;
}

.btn.reaction:hover {
  border: 1px solid white;
}

.btn.reaction.no-video {
  color: rgba(39, 170, 225, 0.3) !important;
}

.btn.reaction.no-video.active {
  color: #27aae1 !important;
}

.btn.reaction.no-video:hover {
  border: 1px solid rgb(39, 170, 225);
}

.btn-modal__approve {
  color: rgb(255, 255, 255) !important;
  background-color: #27aae1 !important;
  font-size: 16px !important;
  padding: 8px 12px !important;
  border-radius: 10px !important;
  font-weight: 600 !important;
  min-width: 6em !important;
}

.btn-modal__cancel {
  color: rgb(255, 255, 255) !important;
  background-color: #868e96 !important;
  font-size: 16px !important;
  padding: 8px 12px !important;
  border-radius: 10px !important;
  font-weight: 600 !important;
  min-width: 6em !important;
}

.botoes-curso .btn {
  padding: 0 !important;
}

.botoes-curso .btn + .btn {
  margin-left: 10px;
}

.botoes-curso {
  margin-bottom: 1em;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}

.discipline-progress {
  border: 1px solid #ececec;
  margin-bottom: 10px;
  padding: 10px 20px;
  color: #535353;
  background: #ededed;
}

.discipline-progress .discipline-progress_content {
  font-weight: 600;
}

.uc-progress {
  margin-bottom: 10px;
  margin-top: -1px;
  color: #535353;
  background: #eee;
}

.uc-progress .discipline_uc-progress {
  background: #f7f7f7;
  border-top: 1px solid #d2d2d2;
}

#sidebar {
  width: 300px;
}

#trilhasAndamento h5,
#progressoIndireto h5 {
  margin: 0;
}

.curso-slider .slick-slide:hover {
  -webkit-transform: scale(2);
          transform: scale(2);
  z-index: 9999;
  transition-delay: 1.5s;
}

.curso-slider .slick-slide {
  transition: 1500ms all;
  position: relative;
  z-index: 2;
}

.curso-slider .slick-track:hover .slick-slide {
  opacity: 0.3;
}

#teste .slick-active:nth-of-type(1) {
  -webkit-transform-origin: left;
          transform-origin: left;
}

.slick-track:hover {
  transition: 1500ms padding-left;
  padding-left: calc(240px / 2);
  transition-delay: 1.5s;
}

.slick-track:hover .slick-slide {
  -webkit-transform: translate3d(calc(-240px / 2), 0, 0);
          transform: translate3d(calc(-240px / 2), 0, 0);
  transition-delay: 1.5s;
}

.slick-slide:hover ~ .slick-slide {
  -webkit-transform: translate3d(calc(240px / 2), 0, 0);
          transform: translate3d(calc(240px / 2), 0, 0);
  transition-delay: 1.5s;
}

.curso-slider .slick-track:hover .slick-slide:hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  opacity: 1;
  transition-delay: 1.5s;
}

.curso-slider.livro .slick-slide {
  width: 150px !important;
}

.slider-grupo {
  position: relative;
  line-height: 100px;
  text-align: center;
  font-size: 26px;
}

.curso-slider.livro .slick-slide img {
  height: 237px !important;
}

.curso-slider .slider_info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  line-height: normal;
  padding: 4%;
  z-index: 99;
  opacity: 0;
  cursor: pointer;
  text-align: left;
  font-size: 50%;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  transition: 450ms opacity;
  color: white;
}

.description-course-card {
  font-size: 10px;
  margin-bottom: 0;
  line-height: normal;
  line-height: initial;
  color: #fff;
}
.curso-slider .slick-list {
  padding-left: calc(-1 * 240px);
  overflow: visible;
}

.curso-slider .slick-slide:hover .slider_info {
  opacity: 1;
}

.slider_actions {
  font-size: 16px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.slider-remove-lane {
  text-align: end;
  font-size: 10px;
}

.slider-remove-lane:hover {
  color: rgb(39, 170, 225);
}

.slider_actions i.active,
.slider_actions i:hover {
  color: rgb(39, 170, 225);
}

.lit-perfil-form ul {
  padding: 0;
  height: 51px;
  border: none;
}

.lit-perfil-form li a {
  height: 100%;
  border: none !important;
}

.lit-perfil-form .nav-tabs a {
  font-size: 1.2em;
}

.lit-perfil-form .nav-link {
  font-weight: 500;
}

.lit-perfil-form .nav-link.active {
  background: #f3f2f1;
  color: #27aae1;
}

.lit-perfil-form select {
  padding: 0.3em 0.7em;
  border: none;
  border-radius: 0px;
  outline: none;
  background-color: #f3f2f1;
  width: 100%;
  line-height: 1.25em;
  font-size: 1rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.lit-perfil-form .linha-form {
  width: 80%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 1rem;
}

.lit-perfil-form .linha-form label {
  font-size: 1em;
  font-weight: 500;
  margin: 0;
}

.carousel-item .btn-outline-green {
  color: #caeb1e;
  border-color: #caeb1e;
  background-color: transparent;
  background-image: none;
}

.carousel-item .btn-outline-green:hover {
  color: rgba(255, 255, 255, 1);
  background-color: #caeb1e;
  background-image: none;
}

.carousel-item .btn-outline-light:hover {
  color: rgba(0, 0, 0, 0.6);
}

.titlePercent .CircularProgressbar {
  width: 75%;
  margin: 10px;
}

.howlong .titulo {
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 10px;
}

.howlong .numInput {
  padding: 0.3em 0.7em;
  border: none;
  border-radius: 0px;
  background: transparent;
  outline: none;
  width: 5em;
  margin-right: 10px;
  background-color: #d4d4d4;
}

.input-range__slider {
  -webkit-appearance: none;
          appearance: none;
  background: #bfe8fb;
  border: 1px solid #bfe8fb;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.75rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  width: 1rem;
}

.input-range__slider:active {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}

.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
}

.input-range__slider-container {
  transition: left 0.3s ease-out;
}

.input-range__label {
  color: black;
  font-size: 0.8rem;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
  bottom: -0.23rem;
  position: absolute;
}

.input-range__label--min {
  left: 0;
}

.input-range__label--max {
  right: -12%;
}

.input-range__label--value {
  display: none;
}

.input-range__label-container {
  left: 0;
  position: relative;
}

.input-range__label--max .input-range__label-container {
  left: 0;
}

.input-range__track {
  background: #d4d4d4 !important;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.5rem;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}

.input-range--disabled .input-range__track {
  background: #eeeeee;
}

.input-range__track--background {
  left: 40px;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: #22ade8 !important;
}

.input-range {
  height: 1rem;
  margin-top: 1em;
  position: relative;
  max-width: 400px;
}

/* # sourceMappingURL=index.css.map */

.assuntos {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.assuntos > .btn {
  margin-left: 5px;
  margin-top: 5px;
}

.card-wrapper-p {
  max-width: 100%;
  width: 25%;
}

@media (min-width: 673px) {
  .card-wrapper-p {
    max-width: calc((100% / 2) - 10px);
  }
}

@media (min-width: 940px) {
  .card-wrapper-p {
    max-width: calc((100% / 3) - 10px);
  }
}

@media (min-width: 1167px) {
  .card-wrapper-p {
    max-width: calc((100% / 4) - 10px);
  }
}

@media (min-width: 1600px) {
  .card-wrapper-p {
    max-width: calc((100% / 6) - 10px);
  }
}

.card-whatnow {
  max-width: 292px;
  margin-bottom: 1em;
  background: white;
  height: 100%;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.card-whatnow img {
  width: 100%;
  height: auto;
}

.card-whatnow .titulo {
  font-weight: bold;
  padding: 10px;
}

.card-whatnow .titulo i {
  margin-right: 5px;
}

.card-whatnow .desc {
  margin-left: 10px;
  margin-bottom: 15px;
}

@-webkit-keyframes loading-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loading-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#trilhasAndamento .card-header .row.collapsed:after {
  position: relative;
  font-size: 20px;
  font-family: "FontAwesome";
  content: "\f105";
  color: grey;
}

#trilhasAndamento .card-header .row:after {
  position: relative;
  font-size: 20px;
  font-family: "FontAwesome";
  content: "\f107";
  color: grey;
}

.select-lit .Select-control,
.select-lit.is-open.is-focused .Select-control,
.select-lit.Select.is-focused:not(.is-open) > .Select-control {
  background-color: #f3f2f1;
  border: none;
  box-shadow: none;
  border-radius: 0px;
}

.select-lit .Select-menu-outer {
  background-color: #f3f2f1;
  border: none;
}

.select-lit .Select-menu-outer .Select-option {
  background-color: #f3f2f1;
}

#atendimento .atendimento__container {
  background: white;
  padding: 25px;
}

#atendimento .btn__atendimento {
  color: #27aae1;
  background-color: #e9ecef;
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 10px;
  font-weight: 600;
  min-width: 6em;
}

.navbar.navbar-lit {
  padding: 0.2em 0.8em;
  margin-bottom: 0;
  color: white;
  transition: margin-top 0.4s;
}

.navbar.navbar-lit-light {
  background: transparent;
}

.navbar.navbar-lit-gradient {
  filter: progid:DXImageTransform.Microsoft.gradient( GradientType=1, startColorstr=#27AAE1, endColorstr=#FCEE1E);
  background-image: linear-gradient(to right, #27aae1 0%, #fcee1e 100%);
  background-image: -o-linear-gradient(to right, #27aae1 0%, #fcee1e 100%);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    right bottom,
    color-stop(0%, #27aae1),
    color-stop(100%, #fcee1e)
  );
  opacity: 0.95;
}

.navbar.navbar-lit:hover,
#header-conteudo:hover {
  opacity: 1 !important;
}

@media (min-width: 991px) {
  .navbar-collapse div:nth-child(2n) {
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

@media (max-width: 991px) {
  .navbar-collapse div .navbar-brand {
    display: none;
  }

  .navbar-collapse div:nth-child(2n) {
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}

.navbar-brand img {
  /* width: 70%; */
  margin-left: 15px;
}

/* .navbar-lit .form-control {

  background: transparent;
  border: 1px solid rgba(255,255,255, 0.25);
  border-radius: 0.8rem;
  font-weight: 600;
  color: #fff !important;
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#34b0ae, endColorstr=#60bc84);
  background-image: -webkit-linear-gradient(90deg, #34b0ae 0, #60bc84);
  background-image: -webkit-gradient(linear, left top, right top, from(#34b0ae), to(#60bc84));
  background-image: -webkit-linear-gradient(left, #34b0ae, #60bc84);
  background-image: -o-linear-gradient(left, #34b0ae 0, #60bc84 100%);
  background-image: linear-gradient(90deg, #34b0ae 0, #60bc84);
  background-image: -o-linear-gradient(to right, #34b0ae 0, #60bc84 100%);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    right bottom,
    color-stop(0, #34b0ae),
    color-stop(100%, #60bc84)
  );
} */

.wrapper-navbar-lit-search {
  margin-left: calc(
    -164px + 31%
  ) !important; /* Maintain search bar aligned with the search input from search page */
}

.navbar-lit-search {
  margin-left: 25px;
  color: #fff !important;
  border-radius: 0.8rem 0 0 0.8rem !important;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.3) !important;
  border-left: 1px solid rgba(255, 255, 255, 0.25) !important;
  border-top: 1px solid rgba(255, 255, 255, 0.25) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25) !important;
}

.navbar-lit ul#navbarNavDropdown {
  padding: 0;
  color: white;
}

.navbar-lit ul#navbarNavDropdown li a.nav-link {
  color: #fff !important;
}

.navbar-lit .collapse.show ul#navbarNavDropdown li a {
  text-align: right;
}

.navbar-lit ul#navbarNavDropdown li .dropdown-menu a {
  color: black !important;
}

.navbar-lit .collapse.show .dropdown-menu {
  background: transparent;
}

@media (min-width: 991px) {
  .navbar-lit .form-large {
    width: 100%;
  }
}

.navbar-lit .collapse.show ul#navbarNavDropdown li .dropdown-menu a {
  color: white;
  text-align: right;
}

.navbar-lit .collapse.show .form-large {
  width: 100%;
}

.btn-PI__cancel {
  color: #000 !important;
  background-color: #ccc !important;
  font-size: 16px !important;
  padding: 8px 12px !important;
  border-radius: 10px !important;
  min-width: 6em !important;
}

.btn-PI__approve {
  color: #eee !important;
  background-color: #27aae1 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 8px 12px !important;
  border-radius: 10px !important;
  min-width: 6em !important;
}

.select-PI__formacao {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  width: 450px;
  padding: 5px 0px 5px 25px;
  color: slategrey;
}

.select-PI__formacao::-ms-expand {
  display: none;
}

/* ----------------- Pagination Buscador ----------------- */

#buscador__pagination .padR20 {
  padding-right: 20px;
}

#buscador__pagination .noTriangleColor {
  border-color: #797979;
}

#buscador__pagination .triangle-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 10px solid #27aae1;
  border-bottom: 10px solid transparent;
}

#buscador__pagination .triangle-right {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 10px solid #27aae1;
  border-bottom: 10px solid transparent;
}

#buscador__pagination .navigation {
  font-size: 25px;
  color: #797979;
}

#buscador__pagination .active {
  color: #27aae1;
}

/* ------------------------------------------------------------ */

#modalShare .Select-control {
  background: #f3f2f1 !important;
  border: none !important;
  box-shadow: none !important;
}

#modalGroup .modal-body#modalShare input {
  padding: 8px 0 12px;
}

.datePicker {
  background-color: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 0.25rem !important;
}

#whatnow-pages.pagination {
  display: inline-block;
}

#whatnow-pages.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
}

#whatnow-pages.pagination a.active {
  background-color: #4caf50;
  color: white;
}

#whatnow-pages.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.card-wrapper-p {
  margin-right: 10px;
  margin-top: 10px;
}

.card-wrapper-p .card, .card-wrapper-card  {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  height: 100%;
}

.card-wrapper-p .card:hover, .card-wrapper-card:hover {
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
  cursor: pointer;
}

.carousel-control-prev-icon {
  -webkit-filter: invert(50%);
          filter: invert(50%);
}

.dropdown-menu.video-control {
  background: #27aae1;
}

.dropdown-menu.video-control .dropdown-item {
  color: white;
  transition: all 0.2s ease-in-out;
}

.dropdown-menu.video-control .dropdown-item:hover {
  background: rgba(0, 0, 0, 0.3);
}

@media (max-width: 400px) {
  .input-range {
    width: 90%;
  }
}

.flex-center {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.margin-top-20 {
  margin-top: 20px;
}

.block-duvidas {
  background: rgb(248, 248, 250);
  color: #27aae1;
  width: 100%;
  font-size: 1em;
  font-weight: 500;
  padding: 8px 3px 3px 20px;
  cursor: pointer;
}

.circle {
  background: #CED5E1;
  border: 1px solid #CED5E1;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.circle.active {
  background: #40637C;
  border-color: #40637C;
}

.circle.active i {
  color: #fff;
}

.circle i {
  font-size: 9px;
  color: #ededed;
}

.player-wrapper {
  position: relative;
}

.player-wrapper > .react-player {
  background: rgba(0, 0, 0, 0.1);
  line-height: 0; 
}

.player-wrapper-audio {
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  padding: 10px;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.list-result-PI {
  padding-left: 40px;
  padding-top: 0px;
  list-style: disc;
}

.box-insights {
  padding: 5px 5px 0 5px;
  margin-top: 15px;
  margin-bottom: 10px;
  background-color: #eeeeee;
  border: 1px solid #b1b1b1;
}

.hr-md-margin {
  margin-top: 5px;
  margin-bottom: 5px;
}

.modal-resultado-pi {
  width: 100%;
  max-width: 1000px !important;
}

.span-saiba-mais-pi {
  font-size: "1.0em";
  color: "#58595b";
}

.text-body-pi p {
  font-size: 1.1rem;
  margin-bottom: 0;
}

.lit-modal-covidar-grupo {
  background: transparent !important;
  padding: 0 !important;
  width: 100%;
}
.content-onboarding-curso {
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  text-align: justify;
}

.content-onboarding-curso p {
  font-size: 1em;
}

.btn-xsm {
  padding: 0.15rem 0.2rem;
  font-size: 0.835rem;
  line-height: 1.2;
}

.btn-xsm-outline-save-lit {
  background-color: var(--blue);
  border: 1px solid var(--blue);
  color: #fff;
  border-radius: 5px;
}

.btn-xsm-outline-save-lit:hover {
  background-color: #fff;
  color: #000;
}

.btn-xsm-outline-cancel-lit {
  background-color: #d8d8d8;
  border: 1px solid #d8d8d8;
  color: #000;
  border-radius: 5px;
}

.btn-xsm-outline-cancel-lit:hover {
  background-color: #fff;
  color: #000;
}

.block-sobre {
  padding: 0.5rem 0.5rem;
}

.buttonNotif {
  color: white;
  display: inline-block;
  position: relative;
  padding: 2px 5px;
  font-size: 20px;
  cursor: pointer;
}

.button__badge {
  background-color: #fa3e3e;
  border-radius: 50%;
  color: white;
  padding: 1px 6px;
  font-weight: bold;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.text-notification {
  display: inline-block;
  /* width: 340px; */
  line-height: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Notifications  */
.mark-read {
  color: var(--blue);
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.not-selection {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.slider-width {
  width: 260px !important;
}

.flag-new {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.flag-new span {
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #d7df23;
  background: linear-gradient(#d7df23 0%, #d7df23 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
}
.flag-new span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #d7df23;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #d7df23;
}
.flag-new span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #d7df23;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #d7df23;
}

.flag-promo {
  position: absolute;
  right: -3px;
  top: -4px;
  z-index: 1;
  overflow: hidden;
  width: 95px;
  height: 95px;
  text-align: right;
}
.flag-promo span {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  padding: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 125px;
  display: block;
  background: rgba(2, 196, 255, 1);
  background: linear-gradient(
    rgba(2, 196, 255, 1) 0%,
    rgba(2, 196, 255, 1) 100%
  );
  position: absolute;
  top: 21px;
  right: -27px;
  color: #fff !important;
}

.flag-myplan span {
  background: linear-gradient(
    rgba(142, 230, 123, 1) 0%,
    rgba(142, 230, 123, 1) 100%
  );
}
.flag-promo span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
}
.flag-promo span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
}

.flag-promobf {
  position: absolute;
  right: -3px;
  top: -4px;
  z-index: 1;
  overflow: hidden;
  width: 95px;
  height: 95px;
  text-align: right;
}

.flag-promobf span {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  padding: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 125px;
  display: block;
  background: rgb(0, 0, 0);
  background: linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
  position: absolute;
  top: 21px;
  right: -27px;
  color: #fcec00 !important;
}
.flag-promobf span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
}
.flag-promobf span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
}

/*
.flag-new {
  font-size: 10px;
  position: absolute;
  right: -7px;
  top: 7px;
  transform: rotate(40deg);
  line-height: normal;
  border-radius: 5px;
  background-color: #d7df23;
  padding: 3px 15px;
  font-weight: bold;
} */

.input-inline-block {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.gender-input {
  display: none;
}

.gender-label {
  padding: 5px;
  background-color: #d8d8d8;
  color: #000;
  border-radius: 10px;
  margin: auto 5px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  content: " ";
}

.gender-input:checked + .gender-label {
  -webkit-animation: vibrationChecked 300ms linear;
          animation: vibrationChecked 300ms linear;
  background-color: var(--blue);
  color: #fff;
}

.gender-input:checked + .gender-label::before {
  content: "\2713";
}

@-webkit-keyframes vibrationChecked {
  33% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  66% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

@keyframes vibrationChecked {
  33% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  66% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

.lane-cursando {
  width: 265px;
  margin: 10px;
  position: relative;
  line-height: 100px;
  text-align: center;
  font-size: 26px;
}

.invoice-title h2,
.invoice-title h3 {
  display: inline-block;
}

.table > tbody > tr > .no-line {
  border-top: none;
}

.table > thead > tr > .no-line {
  border-bottom: none;
}

.table > tbody > tr > .thick-line {
  border-top: 1px solid #999;
}

.badge-lit-info {
  background-color: #43b3c8;
}

.badge-lit-success {
  background-color: #a6ce39;
}

.badge-lit-warning {
  background-color: #fcee1e;
}

.admin-evaluation {
  background: #fff;
  padding: 20px 0;
  margin-top: 50px;
  margin-bottom: 50px;
}

#admin .group-item[aria-expanded="false"]::after {
  content: " \f107";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 5px;
}

#admin .group-item[aria-expanded="true"]::after {
  content: " \f106";
  font-family: FontAwesome;
  display: inline;
  text-align: right;
  padding-left: 5px;
}

.pdf-wrapper:-webkit-full-screen {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.align-sub {
  vertical-align: sub;
}

.circle.assessment {
  background: #3cd99e;
  border-color: #3cd99e;
}

.circle.assessment i {
  color: #fff;
}

.toogleLanes {
  position: absolute;
  top: 9px;
  right: 16px;
  font-size: 20px;
  color: #fff;
}

.br-8 {
  border-radius: 0.8em;
}

.swal-overlay {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.PDFPage > canvas {
  max-width: 1070px;
  width: 100% !important;
  height: auto !important;
}

.PDFPageTermosPoliticas > canvas {
  max-width: 1070px;
}

.top-position {
  top: 23px;
}

/* @media screen and (min-width: 500px) and (max-width: 1100px) {
  .PDFPage > canvas {
    max-width: 480px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .PDFPage > canvas {
    max-width: 680px;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .PDFPage > canvas {
    max-width: 720px;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1720px) {
  .PDFPage > canvas {
    max-width: 950px;
  }
} */

.btn-outline-lit {
  border-color: rgb(67, 219, 162);
  color: rgb(67, 219, 162);
  background-color: transparent;
  background-image: none;
}

.btn-outline-lit.active {
  color: #fff;
  background-color: rgb(67, 219, 162);
  border-color: rgb(67, 219, 162);
}

.btn-outline-lit:hover {
  background-color: rgb(67, 219, 162);
  color: #fff;
}

.btn-bradesco {
  background: #fb154c;
  border: #fb154c;
  border-radius: 50px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  width: 100%;
  height: 40px;
}

.btn-litpass {
  background: #27aae1;
  border: #fb154c;
  border-radius: 10px;
  color: #fff;
  text-align: center;
  width: auto;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.white-space-pre {
  white-space: pre;
}

.input-bradesco {
  -webkit-appearance: none;
  background: #fff !important;
  border-radius: 50px !important;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border: 1px solid #eee;
  box-shadow: 0px 2px 7px -1px rgba(73, 73, 73, 1) !important;
}

/* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .col-md-7 {
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .col-md-5 {
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-ipad-1 {
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-ipad-2 {
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2;
  }
}

/* Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* ----------- iPad ----------- */
/* Portrait and Landscape */
@media only screen and (min-width: 768px) and (max-height: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
}

@media only screen and (min-width: 320px) and (max-height: 670px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-width: 768px) and (max-height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .col-md-7 {
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .col-md-5 {
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-ipad-1 {
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-ipad-2 {
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2;
  }
}

/* Landscape */
@media only screen and (min-width: 768px) and (max-height: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* STEPS */

.multi-step {
  position: relative;
}

ol.progtrckr {
  list-style-type: none;
  padding: 0;
  /* position: absolute;

  left: 50%;
  transform: translate(-50%, 0);
  bottom: -70px;
  width: 90%; */
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 5px solid #ccc;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 5px solid #cccccc;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 5px solid #005aa4;
}

ol.progtrckr li:after {
  content: "\00a0\00a0";
}

ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: "";
  color: silver;
  background-color: #ccc !important;
  width: 1.5em;
  height: 1.5em;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #005aa4;
}

ol.progtrckr li.progtrckr-doing:before {
  content: "";
  color: white;
  background-color: #cccccc;
  width: 1.5em;
  height: 1.5em;
  border: 2px solid #005aa4;
  border-radius: 1.2em;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #005aa4;
  width: 1.5em;
  height: 1.5em;
  border-radius: 1.2em;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #fff;
}

ol.progtrckr li.progtrckr-doing:last-child {
  color: black;
  border-bottom: 5px solid #005aa4;
}

.custon-button {
  background: #fff;
  padding: 10px;
  border-radius: 50%;
  border: 2px solid #333;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #333;
  outline: none;
  cursor: pointer;
}

.animated {
  -webkit-animation: rotation 2s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

/* END STEPS */

#modal-plan {
  background-image: linear-gradient(
    45deg,
    rgba(2, 196, 255, 1) 25%,
    rgba(142, 230, 123, 1) 100%
  );
  /* min-height: 100vh; */
  height: 100%;
  overflow: auto !important;
}

#modal-ajuda {
  background-image: #eee;
  /* min-height: 100vh; */
  height: 100%;
  overflow: auto !important;
}

#openModal {
  border: 0;
  cursor: pointer;
}

.val-err-tooltip {
  background-color: red;
  padding: 3px 5px 3px 10px;
  font-size: 14px;
  color: #fff;
}

.footer-buttons {
  margin-top: 10px;
}

html .step3 label,
html .step4 label {
  font-size: 20px;
  text-align: left;
}

html .form-horizontal .control-label {
  text-align: left;
}

.review .txt {
  font-size: 20px;
  text-align: left;
  margin: 0;
  padding: 0;
}

html body .saving {
  background-color: #5cb85c;
  width: 90%;
  padding: 5px;
  font-size: 16px;
}

code {
  position: relative;
  left: 12px;
  line-height: 25px;
}

.eg-jump-lnk {
  margin-top: 50px;
  font-style: italic;
}

.lib-version {
  font-size: 12px;
  background-color: rgba(255, 255, 0, 1);
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px;
}

html .content {
  margin-left: 10px;
}

span.red {
  color: #d9534f;
}

span.green {
  color: #3c763d;
}

span.bold {
  font-weight: bold;
}

html .hoc-alert {
  margin-top: 20px;
}

html .form-block-holder {
  margin-top: 20px !important;
}

.imageloader {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}

.btn-limit {
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out;
}

.grupo-interesse .btn-limit {
  color: #6d6e71;
}

.sc-Rmtcm {
  background: transparent !important;
}

.sc-bRBYWo {
  background: transparent !important;
}

.space {
  width: 48%;
}

.box-toggle {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 20px;
}

.box-toggle span {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
}

.box-toggle button.space-mensal {
  margin-left: 7px;
}

.box-info-title {
  color: #495057;
  font-weight: lighter;
}

.toggle-action {
  background: no-repeat;
  border: 0;
  color: #fff;
  cursor: pointer;
  outline: none;
}

button:active,
button:focus {
  -moz-outline-style: none;
  outline: none;
}

#btnBack {
  color: #fff;
  background: #868e96;
  border-radius: 10px;
  max-width: 200px;
}

.swal-modal {
  max-width: 700px !important;
  width: 100%;
}

@media (max-width: 455px) {
  .swal-button-container {
    width: 100%;
    margin: 5px 0;
  }
  .btn-alert {
    width: 100% !important;
  }

  /* .btn-alert + button {
    margin-top: 10px;
  } */
}

.btn-alert-finish-assessment {
  background-color: #7ae190;
  color: #fff;
  border-radius: 5px !important;
}

.btn-alert-finish-assessment:hover {
  background-color: #7ae190 !important;
  opacity: 0.9 !important;
}

.btn-alert-exit-assessment {
  background: transparent;
  color: #555;
}

.btn-alert-exit-assessment:hover {
  background: none !important;
}

.btn-alert-cancel-assessment {
  background-color: #efefef;
  color: #555;
  border-radius: 5px !important;
}

.btn-alert-cancel-assessment:hover {
  background-color: #efefef !important;
  opacity: 0.9 !important;
}

.btn-alert-stay-assessment {
  background-color: #27aae1;
  color: #fff;
  border-radius: 5px !important;
}

.btn-alert-stay-assessment:hover {
  background-color: #27aae1 !important;
  opacity: 0.9 !important;
}

@media (min-width: 1024px) {
  .container-img {
    max-width: 231px;
  }
}

.container-logo-navbar {
  background: red;
  text-align: center;
}

.sub-title-logo {
  display: block;
  margin-top: 2px;
  font-size: 20px;
}

.btn-alert,
.btn-alert:focus,
.btn-alert:visited,
.btn-alert:active {
  outline: 0;
  box-shadow: none !important;
}

.btn-alert {
  padding: 9px 20px;
  border-radius: 30px;
  min-width: 200px;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  font-size: 16px;
  /* font-family: 'Lato', sans-serif; */
  /* font-weight: 600; */
  border: 2px solid transparent;
}
.btn-alert-confirm {
  background: white;
  color: rgba(2, 196, 255, 1);
  border: 2px solid rgba(2, 196, 255, 1);
}

.btn-alert-confirm:hover {
  background-image: linear-gradient(
    45deg,
    rgba(2, 196, 255, 1) 0%,
    rgba(142, 230, 123, 1) 100%
  );
  color: white;
}

.btn-alert-confirm-onboarding {
  background: white;
  /* color: rgba(2, 196, 255, 1);
  border: 2px solid  rgba(2, 196, 255, 1); */
  color: #27aae1;
  border: 2px solid #27aae1;
}

.btn-alert-confirm-onboarding:hover {
  /* background-image: linear-gradient(45deg, rgba(2, 196, 255, 1) 0%, rgba(142, 230, 123, 1) 100%); */
  color: white;
  background: #27aae1 !important;
}

.destaque-modal {
  font-weight: bold;
}
.no-margin {
  margin: 0;
}

.loadingNavBarMyLibrary {
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #eeeeee;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  bottom: 0;
  left: 0;
}

.loadingImgNavBarMyLibrary {
  -webkit-filter: invert(75%);
          filter: invert(75%);
}

.destaque {
  font-weight: bold;
}
.underline {
  text-decoration: underline;
}

.box-tabs {
  background: transparent;
  padding: 10px 0;
  /* padding-bottom: 20px; */
}

.headerTab {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.border-top {
  border-top: 1px solid #ccc;
  padding-bottom: 5px;
}

#btn-tab,
#btn-tab:focus,
#btn-tab:visited,
#btn-tab:active {
  outline: none;
  box-shadow: none;
}

.no-m-b {
  margin-bottom: 0;
}

.link-destaque,
.link-destaque:hover {
  text-decoration: underline;
  color: #0077b5;
  cursor: pointer;
}

.listStyle {
  margin-top: 0;
  margin-bottom: 0;
  list-style: disc;
  padding-bottom: 0;
}

.listStyle li {
  font-size: 1.1em;
  margin-bottom: 0.8rem;
}

.wdc-tooltip {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
  z-index: 9999;
  font-size: 10px;
  line-height: 1.5;
  opacity: 0.9;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  visibility: hidden;
  opacity: 0;
  transition: all ease-in-out 150ms;
}
.wdc-tooltip.active {
  visibility: visible;
  opacity: 1;
  top: -10px;
}
.wdc-tooltip span {
  display: block;
  width: 100px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  background-color: #27aae1;
  border-radius: 5px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  position: relative;
  font-weight: bold;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 5px 10px;
}
.wdc-tooltip span:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  border-top-color: #27aae1;
  left: 50%;
  bottom: 0;
  -webkit-transform: translate(-50%, 100%);
          transform: translate(-50%, 100%);
}

#MathJax_Message {
  display: none !important;
}

.card-img-top {
  /* width: 100% !important; */
  width: 100% !important;
  min-height: 160px;
}

.modal-force-overlay{
  z-index: 10002 !important;
}

/*
* Modal tanancy
*/
.tenancyModalOverlay {
  background: #eeeeee !important;
}

.tenancyModal {
  background: #eeeeee !important;
  max-width: 700px;
  width: 100%;
  min-height: 300px;
  color: #FFFFFF !important;
}

.registerPasswordMobileModalOverlay {
  background: rgba(0,0,0,0.5) !important;
}

.registerPasswordMobileModal {
  background: #eeeeee !important;
  max-width: 400px;
  height: 390px;
  color: #FFFFFF !important;
}

.react-responsive-modal-root{
  z-index: 10003 !important;
}

.tenancyModal{
  overflow: hidden !important;
  max-width: 820px !important;
  box-shadow: none !important;
}

.scroll-tenant-modal{
  overflow-y: auto !important;
  max-width: 820px !important;
}

.tenancyModal .react-responsive-modal-closeButton{
  fill:rgb(109, 109, 109) !important;
}

.scroll-tenant-modal::-webkit-scrollbar {
   width: 12px;
}

.scroll-tenant-modal::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.4); 
   border-radius: 10px;
}

.scroll-tenant-modal::-webkit-scrollbar-thumb {
   border-radius: 10px;
   -webkit-box-shadow: inset 0 0 6px rgba(85, 85, 85, 0.7); 
}

.modal-responsible-form{
  min-width: 700px !important;
  padding: 20px;
}

.modal-close-light .react-responsive-modal-closeButton{
  fill:rgb(255, 255, 255)
}

.popover-lit{
  z-index: 9999999 !important;
}

.react-code-input {
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

/*
Sidebar
*/
#accordinUC .collapse:last-child .show,
#accordinUC div[data-course-type='course'].show{
  background: #f2f4f7;
  border-radius: 10px;
  position: relative;
  /* box-shadow: 0 5px 10px rgba(0,0,0,.1); */
  padding: 10px;
}

#accordinUC .collapse:last-child .show:after,
#accordinUC div[data-course-type='course'].show:after{
  content: "";
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #f2f4f7;
  position: absolute;
  top: -20px;
  left: 60px;
}

#accordinUC .collapse{
  transition: .2s;
}

.no-max-width{
  max-width: none !important;
}

.font-lato {
  font-family: 'Lato', sans-serif;
}

/* 
*
* APRESENTACAO DO CURSO
*
*/
.custom-tabs-lit .nav-item{
  background-color: transparent !important;
  border-color: white;
}
.custom-tabs-lit .nav-item a:hover{
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}
.acordion-lit{
  text-align: left !important;
}
.acordion-lit .card-header{
  background-color: transparent !important;
}
.acordion-lit .card-header span{
  color: #9a9a9a
}
.custom-tabs-lit .nav-item a{
  background-color: transparent !important;
  font-family: "Lato", sans-serif !important;
  font-size: 15px;
}
.custom-tabs-lit .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  border-bottom: 3px solid #495057;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  font-weight: 400;
}
.custom-tabs-lit{
  border-bottom: none !important;  
}
.text-color-gray p{
  color: #474a4e !important;
}
.blue-p-a a, .blue-p-a i{
  color: #27AAE1;
}

.acordion-lit .card .card-header .card-link:after {
  content: "\F106";
  font-family: 'FontAwesome';
  font-weight: 900;
  float: right;
  font-size: 20px;
  color: #27AAE1;
}

.acordion-lit .card .card-header .card-link.collapsed:after {
  content: "\F107";
}

.no-hover:hover{
  background: transparent !important;
}

.fieldset-lit{
  background: white;  
  box-shadow: rgba(0,0,0, 0.1) 5px 5px 20px;
  margin-bottom: 10px;
  border: 2px solid #dbdbdb !important;
  border-radius: 5px;
}

.fieldset-lit legend{
  width: auto !important;
  padding: 0 20px !important;
  background-color: white;
}

/* Requirements */
.scrolling-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  padding: 20px 0;
}
.scrolling-wrapper .card {
  display: inline-block;
  width: auto;
  min-width: 200px; /* Largura mínima dos cards */
}

.green-lit{
  color: #54c3a0 !important
}

.gray-lit{
  color: #6d6e71 !important
}

.bg-yellow-lit{
  background-color: rgb(252, 238, 30) !important;
}
/* End Course Presentation */

/* Login screen backgroound contained */
#login .alice-carousel__stage-item div{
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.modal-large-lit{
  max-width: 1200px !important;
}
.modal-large-lit .modal-body{
  padding: 0 !important;
  min-width: 0 !important;
}

.dark-mode, .dark-mode #body-conteudo, .dark-mode .navbar-nav .dropdown-menu {
  background-color: black !important;
}

.dark-mode #root, 
.dark-mode #root #header-conteudo, 
.dark-mode #root #header-conteudo div, 
.dark-mode .disciplinaSidebar, 
.dark-mode .container-fluid, 
.dark-mode .container-fluid div, 
.dark-mode .container-fluid label, 
.dark-mode .container-fluid .tab-content, 
.dark-mode .container-fluid .tab-pane, 
.dark-mode .container-fluid .tab-pane div, 
.dark-mode .container-fluid .tab-pane span, 
.dark-mode input, 
.dark-mode textarea, 
.dark-mode #accordionExample button, 
.dark-mode #accordionExample span, 
.dark-mode #accordionExample i, 
.dark-mode .container-fluid a, 
.dark-mode small, 
.dark-mode .navbar-lit ul#navbarNavDropdown li .dropdown-menu a,
.dark-mode #root .assemments-lits div,
.dark-mode #root .assemments-lits,
.dark-mode #root nav,
.dark-mode .loading-course-screen,
.dark-mode .loading-course-screen div,
.dark-mode .loading-course-screen p,
.dark-mode .tenancyModalOverlay,
.dark-mode .tenancyModal,
.dark-mode .tenancyModal h2,
.dark-mode .react-responsive-modal-closeButton,
.dark-mode .course-presentation,
.dark-mode .course-presentation .title-description{
  background-color: black !important;
  color: white !important
}

.dark-mode .course-presentation{
  border-bottom: 1px solid #3a3a3a;
}

.dark-mode .container-fluid #pdf {
  color: #000 !important;
  color: initial !important;
}

.dark-mode #root #accordionExample div[data-for="disciplina"] {
  background: rgba(255, 255, 255, .1) !important;
  color: white !important
}

.dark-mode #root #accordionExample div[data-for="disciplina"] span,
.dark-mode #root #accordionExample i, 
.dark-mode #root #accordionExample span {
  background: transparent !important;
}

.dark-mode #pdf-div-id div {
  background-color: transparent !important;
  background-color: initial !important;
  color: #000 !important;
  color: initial !important;
}

.dark-mode #accordinUC div[data-course-type='course'].show, 
.dark-mode #accordinUC div[data-course-type='course'].show span, 
.dark-mode #accordinUC div[data-course-type='course'].show i {
  color: white !important;
  background-color: rgba(255,255,255,.1) !important;
}

.dark-mode .conteudo-disciplina .list-group-item_sub2:hover, 
.dark-mode .conteudo-disciplina .list-group-item_sub2.active {
  background-color: #323232 !important;
  color: white !important;
  cursor: pointer;
}

.dark-mode #root h1,
.dark-mode #root h2,
.dark-mode #root h3,
.dark-mode #root h4,
.dark-mode #root h5
.dark-mode #root h6,
.dark-mode #root div,
.dark-mode #root #header-conteudo i,
.dark-mode #sidebar .show .list-child,
.dark-mode #root .assemments-lits div,
.dark-mode #root .assemments-lits span,
.dark-mode #root .assemments-lits i,
.dark-mode #root span {
  color: white !important
}

.dark-mode #accordinUC .collapse:last-child .show:after, 
.dark-mode #accordinUC div[data-course-type='course'].show:after {
  border-bottom: 20px solid #1c1c1c;
}